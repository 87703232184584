import mobileIcon from 'assets/images/icons/app-screen-green.svg';
import testersIcon from 'assets/images/icons/users-circle-green.svg';
import toolsIcon from 'assets/images/icons/tools-green.svg';
import userIcon from 'assets/images/icons/users-circle-green.svg';
import managerSectionIcon from 'assets/images/icons/section.svg';

export const getPermissionList = (formDetails) => {
  return [
    {
      key: 'appUser',
      icon: mobileIcon,
      access: 'text_app_users_input_data',
      ...formDetails?.appUser,
    },
    {
      key: 'managerSections',
      icon: managerSectionIcon,
      access: 'text_manager_sections',
      sections: [...(formDetails?.managerSections ?? [])],
    },
    {
      key: 'supervisor',
      icon: userIcon,
      access: 'text_supervisors_view_data',
      ...formDetails?.supervisor,
    },
    {
      key: 'designer',
      icon: toolsIcon,
      access: 'text_designers_edit_form_data',
      ...formDetails?.designer,
    },
    {
      key: 'testers',
      icon: testersIcon,
      access: 'text_testers_test_forms',
      ...formDetails?.testers,
    },
  ];
};

export const getFormDataSourcesForPermission = (formElements, dataSourcesList) => {
  const filterAbleDataSources = dataSourcesList.filter((dataSource) => {
    if (dataSource.dataSourceSchema.some((s) => s.isKeyFilter)) return true;
    else return false;
  });

  const dataSourceKeyFilterMap = Object.fromEntries(
    filterAbleDataSources.map((d) => [
      d._id,
      d.dataSourceSchema.find((s) => s.isKeyFilter).columnId,
    ]),
  );
  const dataSourceElements = formElements.filter((e) => e.elementType === 'dataSource');

  const usedDataSourceIds = [];
  const dataSourceBindingMap = {};
  dataSourceElements.forEach((element) => {
    const properties = JSON.parse(element.properties);
    if (
      properties?.basicProperties?.dataSource &&
      properties?.basicProperties?.valueColumn &&
      dataSourceKeyFilterMap[properties.basicProperties.dataSource] ===
        properties.basicProperties.valueColumn
    ) {
      usedDataSourceIds.push(properties.basicProperties.dataSource);

      const mappedColumnIds = [];
      mappedColumnIds.push(properties.basicProperties.valueColumn);
      mappedColumnIds.push(
        ...(properties.basicProperties.bindingList ?? []).map((b) => b.columnId),
      );
      dataSourceBindingMap[properties.basicProperties.dataSource] = mappedColumnIds;
    }
  });

  const filteredDataSources = filterAbleDataSources.filter((d) =>
    usedDataSourceIds.includes(d._id),
  );
  return { filteredDataSources, bindingMap: dataSourceBindingMap };
};

export const managerSectionPermissionsList = [
  {
    access: 'form_field_all_manager_section_permission',
    value: 'full_access',
  },
  {
    access: 'form_field_view_manager_section_permission',
    value: 'view_only',
  },
];
