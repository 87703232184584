import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import formGreenIcon from 'assets/images/icons/form-green.svg';
import selectArrow from 'assets/images/select-arrow.png';
import AddSubmission from './AddSubmission';
import Loader from 'components/common-components/Loader';
import Pagination from 'components/common-components/Pagination';
import SelectField from 'components/form-components/SelectField';
import SubmissionFilters from './SubmissionFilters';
import TableComponent from './table/TableComponent';
import { CallAPI } from 'actions/General';
import { defaultSupervisorStatusOptionsList } from 'pages/form/utils';
import { getApprovalStatusVariant, submissionsHeader } from './utils';
import { signatureStartingText } from 'config/config';
import { uploadImage } from 'pages/company/utils';

function Submissions() {
  const { lang } = useSelector((state) => state.language);
  const navigate = useNavigate();
  const location = useLocation();
  const [supervisorStatusOptionsList, setSupervisorStatusOptionsList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [fieldKeys, setFieldKeys] = useState({});
  const [versionsList, setVersionsList] = useState([]);
  const [submissionsMediaObject, setSubmissionsMediaObject] = useState({});
  const [signatureMediaIds, setSignatureMediaIds] = useState({});
  const [submissionsList, setSubmissionsList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [submissionsHeaderList, setSubmissionsHeaderList] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [submissionLoader, setSubmissionLoader] = useState(false);
  const [displayEntries, setDisplayEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addSubmission, setAddSubmission] = useState(false);
  const [supervisorList, setSuperVisorList] = useState([]);
  const [editedSubmissionValues, setEditedSubmissionValues] = useState({});
  const [filterData, setFilterData] = useState({ formId: '', data: '', version: '', status: '' });
  const [showAttachment, setShowAttachment] = useState('attachment');
  const [queryFilter, setQueryFilter] = useState({
    searchText: '',
    searchUser: '',
    fromDate: '',
    toDate: '',
    filterSubmission: [],
    filterOperator: '',
  });
  const [searchState, setSearchState] = useState({ searchText: '', searchUser: '' });
  const [formName, setFormName] = useState('');
  const [formDirection, setFormDirection] = useState('');
  const [scrollTop, setScrollTop] = useState(0);
  const [dataSourceFilters, setDataSourceFilters] = useState([]);
  const [dataSourceFilterData, setDataSourceFilterData] = useState({});
  const [formVersionDetails, setFormVersionDetails] = useState(null);

  const handleSearchStateChange = (field, value) =>
    setSearchState({ ...searchState, [field]: value });

  const changeDisplayEntries = (name, val) => {
    setDisplayEntries(parseInt(val));
    getFormSubmissions(1, parseInt(val));
  };

  const getImageUrl = async (url) => {
    let result = await CallAPI('GET_SUBMISSION_MEDIA_REDIRECT_URL', null, url, null, null);
    if (result.status) {
      return result.data.url;
    } else {
      return '';
    }
  };

  const handleDataSourceFilterDataChange = (name, value) => {
    setDataSourceFilterData({ ...dataSourceFilterData, [name]: value });
  };

  const getFormSubmissions = async (page = 1, limit = 10) => {
    if (filterData.formId) {
      setPage(page);
      setLimit(limit);
      let APICall =
        filterData.data === 'test-data'
          ? 'GET_ALL_TEST_FORM_SUBMISSIONS'
          : filterData.data === 'archive-data'
          ? 'GET_ALL_ARCHIVE_FORM_SUBMISSIONS'
          : 'GET_ALL_FORM_SUBMISSIONS';

      const params = { form: filterData.formId, formVersion: filterData.version, page, limit };

      if (filterData.status !== '') params.filterApprovalStatus = filterData.status;
      if (queryFilter.searchText !== '') params.searchText = queryFilter.searchText;
      if (queryFilter.searchUser !== '') params.userFieldSearch = queryFilter.searchUser;
      if (queryFilter.fromDate !== '') params.fromDate = queryFilter.fromDate;
      if (queryFilter.toDate !== '') params.toDate = queryFilter.toDate;
      if (queryFilter.filterSubmission.length)
        params.filterSubmission = queryFilter.filterSubmission;
      if (queryFilter.filterOperator !== '') params.filterOperator = queryFilter.filterOperator;

      const dataFilterFilledValues = { ...queryFilter.dataSourceFilters };
      for (const key in queryFilter.dataSourceFilters)
        if (dataFilterFilledValues[key] === '') delete dataFilterFilledValues[key];

      if (Object.keys(dataFilterFilledValues).length > 0) {
        params.dataSourceFilters = dataFilterFilledValues;
      } else {
        const allowedDataFiltersPayload = preparePayloadForAllowedFilters(
          formVersionDetails?.dataSourceFilterPermission,
        );
        if (allowedDataFiltersPayload) {
          params.dataSourceId = allowedDataFiltersPayload.dataSourceId;
          params.keyFilterColumnId = allowedDataFiltersPayload.keyFilterColumnId;
          params.filterColumnIds = allowedDataFiltersPayload.filterColumnIds;
        }
      }

      let result = await CallAPI(APICall, params, null, setLoader, null, null);
      if (result.status) {
        let submissionsData = [];
        let submissionsMedia = {};

        const records = result.data.submissions ?? result.data.archives;
        for (let submissionIndex = 0; submissionIndex < records.length; submissionIndex++) {
          const singleSubmission = records[submissionIndex];

          let fieldKeysList = cloneDeep(fieldKeys);
          let singleSubmissionData = {};

          for (let valueIndex = 0; valueIndex < singleSubmission.values.length; valueIndex++) {
            const singleFormEntry = singleSubmission.values[valueIndex];

            let key = singleFormEntry.elementGlobalId;
            let fieldValue = '';
            if (fieldKeysList[key] === 'signature') {
              fieldValue = '';
            } else if (fieldKeysList[key] === 'multiple') {
              fieldValue = singleFormEntry.arrayField;
            } else {
              fieldValue = singleFormEntry.valueField;
            }
            singleSubmissionData[key] = fieldValue;
            submissionsMedia[singleSubmission._id] = submissionsMedia[singleSubmission._id]
              ? submissionsMedia[singleSubmission._id]
              : {};

            submissionsMedia[singleSubmission._id][key] = submissionsMedia[singleSubmission._id][
              key
            ]
              ? submissionsMedia[singleSubmission._id][key]
              : { media: [], note: '' };
            submissionsMedia[singleSubmission._id][key]['media'] = singleFormEntry.media.filter(
              (singleMedia) =>
                singleMedia.imageName.indexOf(signatureStartingText) === -1 &&
                (!singleFormEntry.elementMetaData ||
                  !singleFormEntry.elementMetaData.includes(singleMedia._id)),
            );
            submissionsMedia[singleSubmission._id][key]['note'] = singleFormEntry.notesField
              ? singleFormEntry.notesField
              : '';
            submissionsMedia[singleSubmission._id][key]['mediaTimeStampString'] =
              singleFormEntry.mediaTimeStampString ?? '';
            if (fieldKeysList[key] !== 'signature') delete fieldKeysList[key];
          }
          Object.keys(fieldKeysList).map((singleNonExistKey) => {
            if (fieldKeysList[singleNonExistKey] !== 'signature') {
              singleSubmissionData[singleNonExistKey] = fieldKeysList[singleNonExistKey];
              delete fieldKeysList[singleNonExistKey];
            }
          });
          let mediaCustomFolderId = singleSubmission.mediaCustomFolderId
            ? singleSubmission.mediaCustomFolderId
            : uuidv4();
          submissionsData.push({
            indexed: submissionIndex + 1,
            form_submission_id: singleSubmission._id,
            form_version_id: singleSubmission.formVersion,
            media_custom_folder_id: mediaCustomFolderId,
            ...singleSubmissionData,
            ...{
              firstName:
                singleSubmission && singleSubmission.userFirstName
                  ? singleSubmission.userFirstName
                  : 'N/A',
              lastName:
                singleSubmission && singleSubmission.userLastName
                  ? singleSubmission.userLastName
                  : 'N/A',
              createdAt: singleSubmission.createdAt,
              createdAtTime: singleSubmission.completeDate,
              referenceNumber: singleSubmission.referenceNumber,
              approvalStatus: singleSubmission.approvalStatus,
              entryStatus: singleSubmission.entryStatus,
            },
          });
        }

        setSubmissionsMediaObject(submissionsMedia);
        setSubmissionsList(submissionsData);
        if (page === 1) setTotalRecords(result.data.totalCount);
        attachMedia(cloneDeep(submissionsData), cloneDeep(records));
      }
      setLoader(false);
    }
  };
  const attachMedia = async (submissionData, records) => {
    let submissionDataWithMedia = [];
    let signatureMediaIds = {};
    let fieldKeysList = cloneDeep(fieldKeys);
    for (let submissionIndex = 0; submissionIndex < records.length; submissionIndex++) {
      const singleSubmission = records[submissionIndex];
      let singleSubmissionData = {};
      submissionData.map((singleSubmissionRecord) => {
        if (singleSubmission._id === singleSubmissionRecord.form_submission_id)
          singleSubmissionData = singleSubmissionRecord;
      });

      let fieldValue = null;
      for (let valueIndex = 0; valueIndex < singleSubmission.values.length; valueIndex++) {
        const singleFormEntry = singleSubmission.values[valueIndex];

        let key = singleFormEntry.elementGlobalId;

        if (fieldKeysList[key] === 'signature') {
          let media = singleFormEntry.media;
          for (let mediaIndex = 0; mediaIndex < media.length; mediaIndex++) {
            const singleMedia = media[mediaIndex];

            if (
              (singleMedia && singleMedia.imageName.indexOf(signatureStartingText) === 0) ||
              singleFormEntry.elementMetaData?.includes(singleMedia._id)
            ) {
              signatureMediaIds[singleSubmission._id + '_' + key] = singleMedia._id;
              fieldValue = (
                <img
                  className='theme-width-40'
                  src={await getImageUrl(
                    singleSubmission.form +
                      '/' +
                      singleSubmission.formVersion +
                      '/' +
                      singleMedia.customFolderId +
                      '/' +
                      singleMedia.imageName,
                  )}
                />
              );
              singleSubmissionData[key] = fieldValue;
            }
          }
        }
      }

      submissionDataWithMedia.push(singleSubmissionData);
    }
    setSubmissionsList(cloneDeep(submissionDataWithMedia));
    setSignatureMediaIds(cloneDeep(signatureMediaIds));
  };

  const getFormVersionDetail = async () => {
    if (versionsList.length > 0) {
      let apiPath = filterData.formId + '/';
      let formVersion = '';
      if (filterData.version) formVersion += filterData.version;
      else {
        if (filterData.data !== 'test-data') {
          const filteredVersionList = [...versionsList].filter((v) => v.formState === 'published');
          formVersion += filteredVersionList[filteredVersionList.length - 1].value;
        } else {
          formVersion += versionsList[versionsList.length - 1].value;
        }
      }
      if (formVersion) {
        setLoader(true);
        apiPath += formVersion;
        let result = await CallAPI('GET_FORM_VERSION_DETAIL', null, apiPath, null, null, null);
        if (result.status) {
          let fieldKeysList = {};
          let submissionsHeaderData = cloneDeep(submissionsHeader);
          setFormName(result.data.title);
          setFormDirection(result.data.textDirection);
          const questionList = result.data.versions[0].elements
            .filter(
              (element) => !['page', 'section', 'signature', 'image'].includes(element.elementType),
            )
            .map((element) => {
              const properties = JSON.parse(element.properties);
              const options =
                element.elementType === 'checkList' ? properties.basicProperties.optionList : [];
              const type = [
                'text',
                'location',
                'dateTime',
                'qrCode',
                'dataSource',
                'externalApi',
                'sendTo',
              ].includes(element.elementType)
                ? 'string'
                : ['number', 'ratingSlider', 'numberSlider'].includes(element.elementType)
                ? 'number'
                : ['checkList', 'apiStatus'].includes(element.elementType)
                ? properties?.basicProperties?.multipleChoice
                  ? 'multi-choice'
                  : 'single-choice'
                : '';

              return {
                elementId: element._id,
                elementGlobalId: element.elementGlobalId,
                question: properties.basicProperties.fieldTitle ?? '',
                type,
                options,
              };
            });
          const supervisorQuestionList = result.data.versions[0].supervisorElements
            .filter((element) => !['signature'].includes(element.elementType))
            .map((element) => {
              const properties = JSON.parse(element.properties);
              // const options =
              //   element.elementType === 'status' ? properties.basicProperties.optionList : [];
              const options =
                element.elementType === 'status' ? defaultSupervisorStatusOptionsList : [];

              const type = ['text', 'dateTime'].includes(element.elementType)
                ? 'string'
                : ['number', 'ratingSlider'].includes(element.elementType)
                ? 'number'
                : element.elementType === 'status'
                ? 'single-choice'
                : '';

              return {
                elementId: element._id,
                elementGlobalId: element.elementGlobalId,
                question: properties.basicProperties.fieldTitle ?? '',
                type,
                options,
              };
            });
          setAllQuestions([...questionList, ...supervisorQuestionList]);

          let supervisorElementsList = result.data.versions[0].supervisorElements;
          if (result.data.versions[0].statusElement) {
            if (supervisorElementsList && supervisorElementsList.length > 0)
              supervisorElementsList.push(result.data.versions[0].statusElement);
            else supervisorElementsList = [result.data.versions[0].statusElement];
          }
          supervisorElementsList.map((singleSuperVisorField) => {
            let elementType = singleSuperVisorField.elementType;
            if (elementType !== 'section') {
              let additionalDetail = { elementId: singleSuperVisorField.elementGlobalId };
              let formSupervisorProperties = JSON.parse(singleSuperVisorField.properties);
              if (elementType === 'dateTime')
                elementType = formSupervisorProperties.basicProperties.subType;
              if (elementType === 'status') {
                additionalDetail = {
                  ...additionalDetail,
                  optionList: [
                    { responseText: 'Select Option', value: '' },
                    ...formSupervisorProperties.basicProperties.optionList,
                  ],
                };
                setSupervisorStatusOptionsList(formSupervisorProperties.basicProperties.optionList);
              }
              if (elementType === 'ratingSlider') {
                additionalDetail = {
                  ...additionalDetail,
                  noOfStars: formSupervisorProperties.basicProperties.ratingPointers,
                };
              }
              let key = '';
              let hintText = '';
              if (formSupervisorProperties?.otherProperties?.shortTitle) {
                key = formSupervisorProperties.otherProperties.shortTitle;
                hintText = formSupervisorProperties.otherProperties.fieldTitle;
              } else key = formSupervisorProperties.basicProperties.fieldTitle;
              let headerSupervisorProp = {
                name: singleSuperVisorField.elementGlobalId,
                isSelected: true,
                displayName: key,
                isFilterAble: false,
                type: elementType,
                associatedKey: singleSuperVisorField.elementGlobalId,
                tableType: 'supervisorFields',
                additionalDetail: additionalDetail,
                hintText,
              };
              fieldKeysList[singleSuperVisorField.elementGlobalId] =
                elementType === 'signature'
                  ? 'signature'
                  : elementType === 'date' ||
                    elementType === 'time' ||
                    elementType === 'dateAndTime' ||
                    elementType === 'ratingSlider' ||
                    elementType === 'status'
                  ? ''
                  : '';
              if (elementType === 'date')
                headerSupervisorProp['populateFunc'] = 'populateUnixToDate';
              else if (elementType === 'time')
                headerSupervisorProp['populateFunc'] = 'populateUnixToTime';
              else if (elementType === 'dateAndTime')
                headerSupervisorProp['populateFunc'] = 'populateUnixToDateTime';
              if (elementType === 'status') {
                headerSupervisorProp['populateFunc'] = 'populateStatusSupervisorField';
                headerSupervisorProp['sendRowWithKeyValue'] = true;
                headerSupervisorProp['callbackFunc'] = 'statusChangeCallBack';
                delete headerSupervisorProp.associatedKey;
              } else if (elementType === 'ratingSlider') {
                headerSupervisorProp['populateFunc'] = 'populateWithSliderValue';
                headerSupervisorProp['sendRowWithKeyValue'] = true;
              } else if (elementType === 'webView') {
                headerSupervisorProp['populateFunc'] = 'populateWebViewIcon';
                headerSupervisorProp['sendRowWithKeyValue'] = true;
              }
              submissionsHeaderData.push(headerSupervisorProp);
            }
          });

          const index = submissionsHeaderData.findIndex(
            (h) => h.tableType === 'supervisorFields' && h.type === 'status',
          );
          if (index > -1) {
            submissionsHeaderData.push(submissionsHeaderData.splice(index, 1)[0]);
          }

          let sectionTitle = '';

          result.data.versions[0].elements.map((singleBasicField) => {
            let additionalDetail = {};
            let singleBasicProperty = JSON.parse(singleBasicField.properties);

            let key = '';
            let hintText = '';
            if (singleBasicProperty?.otherProperties?.shortTitle) {
              key = singleBasicProperty.otherProperties.shortTitle;
              hintText = singleBasicProperty.basicProperties.fieldTitle;
            } else key = singleBasicProperty.basicProperties.fieldTitle;

            let elementId = singleBasicField.elementGlobalId;
            let options = '';
            let elementType = singleBasicField.elementType;
            if (elementType === 'section') {
              sectionTitle = singleBasicProperty?.basicProperties?.sectionTitle;
            }
            if (elementType !== 'page' && elementType !== 'section' && elementType !== 'image') {
              let subType = '';
              if (elementType === 'number' || elementType === 'ratingSlider') {
                additionalDetail = {
                  noOfStars: singleBasicProperty.basicProperties.maxRange
                    ? singleBasicProperty.basicProperties.maxRange
                    : singleBasicProperty.basicProperties.ratingPointers,
                };
              } else if (elementType === 'numberSlider') {
                additionalDetail = {
                  minRange: singleBasicProperty.basicProperties.minRange,
                  maxRange: singleBasicProperty.basicProperties.maxRange,
                };
              } else if (elementType === 'checkList') {
                options = singleBasicProperty.basicProperties.optionList;
                additionalDetail = { optionList: options };
              }
              if (elementType === 'dateTime') {
                subType = singleBasicProperty.basicProperties.subType;
              }
              fieldKeysList[elementId] =
                elementType === 'signature'
                  ? 'signature'
                  : elementType === 'checkList' &&
                    singleBasicProperty.basicProperties.multipleChoice === true
                  ? 'multiple'
                  : '';
              let headerBasicProp = {
                name: elementId,
                isSelected: true,
                displayName: key,
                hintText,
                isFilterAble: false,
                showMediaTimeStamp: singleBasicProperty?.mediaProperties?.showTimeStamp ?? false,
                // isMediaAttached: singleBasicProperty.basicProperties.allowMediaNotes,
                isMediaAttached:
                  ((singleBasicProperty.mediaProperties?.allowPictures ?? false) ||
                    (singleBasicProperty.mediaProperties?.allowVideos ?? false)) &&
                  showAttachment === 'attachment',
                type: elementType,
                associatedKey: elementId,
                tableType: 'basicFields',
                additionalDetail: additionalDetail,
                sectionTitle,
              };
              if (subType === 'date') headerBasicProp['populateFunc'] = 'populateUnixToDate';
              else if (subType === 'time') headerBasicProp['populateFunc'] = 'populateUnixToTime';
              else if (subType === 'dateAndTime')
                headerBasicProp['populateFunc'] = 'populateUnixToDateTime';
              if (elementType === 'checkList') {
                headerBasicProp['populateFunc'] = 'populateSelectedChoiceFromList';
                headerBasicProp['sendRowWithKeyValue'] = true;
              } else if (elementType === 'numberSlider') {
                headerBasicProp['populateFunc'] = 'populateNumberSlider';
                headerBasicProp['sendRowWithKeyValue'] = true;
              } else if (elementType === 'ratingSlider') {
                headerBasicProp['populateFunc'] = 'populateWithSliderValue';
                headerBasicProp['sendRowWithKeyValue'] = true;
              } else if (elementType === 'webView') {
                headerBasicProp['populateFunc'] = 'populateWebViewIcon';
                headerBasicProp['sendRowWithKeyValue'] = true;
                headerBasicProp['callbackFunc'] = 'gotoRecordPreviewPage';
              } else if (elementType === 'comments') {
                headerBasicProp['populateFunc'] = 'populateCommentsIcon';
                headerBasicProp['sendRowWithKeyValue'] = true;
              }
              submissionsHeaderData.push(headerBasicProp);
            }
          });

          setFieldKeys(fieldKeysList);
          setSubmissionsHeaderList(submissionsHeaderData);

          setFormVersionDetails(result.data);
          getUserAllowedFilters(result.data.dataSourceFilterPermission);
        }
      }
    }
  };

  const preparePayloadForAllowedFilters = (dataSourceFilterPermission) => {
    if (dataSourceFilterPermission?.isEnabled) {
      let keyFilterColumnId = null;
      let filterColumnIds = [];

      dataSourceFilterPermission.dataSourceColumns.forEach((column) => {
        if (column.isKeyFilter) keyFilterColumnId = column.columnId;
        else filterColumnIds.push(column.columnId);
      });

      return {
        dataSourceId: dataSourceFilterPermission.dataSourceId,
        keyFilterColumnId,
        filterColumnIds,
      };
    }
  };

  const getUserAllowedFilters = async (dataSourceFilterPermission) => {
    const allowedDataFiltersPayload = preparePayloadForAllowedFilters(dataSourceFilterPermission);
    if (allowedDataFiltersPayload) {
      const response = await CallAPI(
        'GET_USER_ALLOWED_DATA_SOURCE_FILTERS',
        allowedDataFiltersPayload,
        null,
        null,
        null,
        null,
      );
      if (response.status) {
        const columnMap = Object.fromEntries(
          response.data.dataSourceSchema.map((c) => [c.columnId, c.columnName]),
        );

        const filterList = Object.entries(response.data.dataFilters).map(([columnId, values]) => ({
          name: columnId,
          displayName: columnMap[columnId],
          options: [
            { value: '', displayValue: 'Show All' },
            ...values
              .filter((value) => !!value.name)
              .map((value) => ({ value: value.name, displayValue: value.name })),
          ],
        }));

        setDataSourceFilters(filterList);
      }
    }
  };

  const statusChangeCallBack = (rowData) => {
    return (
      <StatusField
        formSubmissionId={rowData.form_submission_id}
        formVersionId={rowData.form_version_id}
        formId={filterData.formId}
        formName={formName}
        valuesList={supervisorStatusOptionsList}
        getFormSubmissions={() => getFormSubmissions(page, limit)}
        isTestSubmission={filterData.data === 'test-data'}
        approvalStatus={rowData.approvalStatus}
      />
    );
  };

  useEffect(() => {
    if (filterData.formId) {
      getFormVersionDetail();
    }
  }, [filterData]);

  useEffect(() => {
    if (formVersionDetails) getFormSubmissions();
  }, [submissionsHeaderList, queryFilter, formVersionDetails]);

  useEffect(() => {
    if (Object.keys(dataSourceFilterData).length > 0) {
      setQueryFilter({ ...queryFilter, dataSourceFilters: dataSourceFilterData });
    }
  }, [dataSourceFilterData]);

  useEffect(() => {
    if (location && location.state && location.state.formId) {
      setFilterData({
        ...filterData,
        formId: location && location.state && location.state.formId ? location.state.formId : '',
        version: location && location.state && location.state.version ? location.state.version : '',
        data:
          location && location.state && location.state.status === 'testing'
            ? 'test-data'
            : 'live-data',
      });

      let formVersions =
        location && location.state && location.state.versionsList
          ? location.state.versionsList
          : [];
      const allFormVersions = formVersions.map((singleVersion) => ({
        displayValue: singleVersion.version,
        value: singleVersion._id,
        formState: singleVersion.formState,
      }));
      setVersionsList([
        { displayValue: 'All', value: '', formState: 'published' },
        ...allFormVersions,
      ]);
    } else navigate('/forms');
  }, [location]);

  const onRowsSelection = (name, value) => {
    let selectedIds = cloneDeep(selectedRows);
    if (name === 'all') {
      submissionsList.map((single_submission) => {
        if (
          value &&
          single_submission.entryStatus === 'complete_entry' &&
          selectedIds.map((e) => e.id).indexOf(single_submission.form_submission_id) === -1
        )
          selectedIds.push({
            id: single_submission.form_submission_id,
            title: formName,
            customFolderId: single_submission.media_custom_folder_id,
          });
        if (!value) selectedIds = [];
        setSelectedRows(selectedIds);
      });
    } else {
      if (value) {
        let customFolderId = '';
        const foundSubmission = submissionsList.find(
          (single_submission) => name === single_submission.form_submission_id,
        );
        if (foundSubmission.entryStatus === 'complete_entry') {
          customFolderId = foundSubmission.media_custom_folder_id;

          selectedIds.push({
            id: name,
            title: formName,
            customFolderId: customFolderId,
          });
        }
      } else selectedIds.splice(selectedIds.map((e) => e.id).indexOf(name), 1);
      setSelectedRows(selectedIds);
    }
  };
  const setTableHeaderChange = (header) => {
    setSubmissionsHeaderList(header);
  };

  const RowClickCallBackAction = async (row) => {
    let supervisorFieldsGlobalIds = [];
    const formSupervisorFields = await CallAPI(
      'GET_SUPERVISOR_FIELDS_OF_FORM_VERSION',
      null,
      row.form_version_id,
      null,
      null,
      null,
    );
    if (formSupervisorFields.status) {
      formSupervisorFields.data.supervisorElements.map((singleSupervisorElement) => {
        supervisorFieldsGlobalIds.push(singleSupervisorElement.elementGlobalId);
      });
    }
    let values = {
      submissionId: row.form_submission_id,
      formVersionId: row.form_version_id,
      mediaCustomFolderId: row.media_custom_folder_id,
      isAnyFieldMissing: false,
      direction: formDirection,
    };
    let supervisors = [];
    submissionsHeaderList.map((singleColumn) => {
      if (
        singleColumn.tableType === 'supervisorFields' &&
        singleColumn.type !== 'status' &&
        supervisorFieldsGlobalIds.indexOf(singleColumn.name) === -1
      ) {
        values.isAnyFieldMissing = true;
      }
      if (
        singleColumn.tableType === 'supervisorFields' &&
        singleColumn.type !== 'status' &&
        supervisorFieldsGlobalIds.indexOf(singleColumn.name) > -1
      ) {
        supervisors.push(singleColumn);
        values[singleColumn.additionalDetail.elementId] = row[singleColumn.name]
          ? row[singleColumn.name]
          : '';
      }
    });

    setEditedSubmissionValues(values);
    setSuperVisorList(supervisors);
    setAddSubmission(true);
  };

  const submitDataForm = async (
    formData,
    submissionId,
    versionId,
    mediaCustomFolderId,
    sendNotificationToInspector,
  ) => {
    setSubmissionLoader(true);
    for (let index = 0; index < formData.length; index++) {
      const singleField = formData[index];
      if (singleField.type && singleField.type === 'signature') {
        if (signatureMediaIds[submissionId + '_' + singleField.elementGlobalId])
          await CallAPI(
            'DELETE_MEDIA',
            {
              folderType: 'submissions',
              mediaId: signatureMediaIds[submissionId + '_' + singleField.elementGlobalId],
              imageName:
                signatureStartingText + submissionId + '_' + singleField.elementGlobalId + '.png',
              mediaType: 'image',
              formId: filterData.formId,
              versionId: versionId,
              customFolderId: mediaCustomFolderId,
            },
            null,
            setLoader,
          );
        let response = await CallAPI(
          'GET_SUBMISSION_MEDIA_UPLOAD_URL',
          {
            imageName:
              signatureStartingText + submissionId + '_' + singleField.elementGlobalId + '.png',
            mediaType: 'image',
            formId: filterData.formId,
            versionId: versionId,
            customFolderId: mediaCustomFolderId,
          },
          null,
          setLoader,
        );
        if (response.status) {
          const blob = await (await fetch(singleField.valueField)).blob();

          await uploadImage(blob, response.data, null);
          singleField.media = [response.data.id];
          singleField.elementMetaData = JSON.stringify({ signature: response.data.id });
          singleField.valueField = '';
          delete singleField.type;
        }
      }
    }

    let result = await CallAPI('UPDATE_SUBMISSION_SUPERVISOR_FIELDS', {
      form: { id: filterData.formId, title: formName },
      formVersion: versionId,
      submissionId: submissionId,
      locale: lang,
      values: formData,
      sendNotificationToInspector,
    });
    setSubmissionLoader(false);
    if (result.status) {
      setAddSubmission(false);
      getFormSubmissions(page, limit);
    } else {
      toast(result.error, {
        style: { borderRadius: '8px', background: 'red', color: '#fff' },
      });
    }
  };

  const actionsCallBack = (action) => {
    setSelectedRows([]);
    if (action === 'delete') getFormSubmissions();
  };

  useEffect(() => {
    const elements = document.getElementsByClassName('submission-table');
    for (let index = 0; index < elements.length; index++) {
      if (elements.item(index).scrollTop !== scrollTop)
        elements.item(index).scrollTo({ top: scrollTop, behavior: 'instant' });
    }
  }, [scrollTop]);

  return (
    <div className='theme-background-white-1'>
      <div className='d-flex gap-3 align-items-center px-4 pt-3'>
        <img src={formGreenIcon} className='svg-black' />
        <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_1'>
          {formName}
        </label>
        {dataSourceFilters.map((filter, index) => (
          <SelectField
            key={index}
            label={
              dataSourceFilterData[filter.name] && dataSourceFilterData[filter.name] !== ''
                ? dataSourceFilterData[filter.name]
                : dataSourceFilterData[filter.name] === ''
                ? 'select_show_all'
                : filter.displayName
            }
            listValues={filter.options}
            icon={arrowDownIcon}
            handleChange={handleDataSourceFilterDataChange}
            selectedValues={[]}
            name={filter.name}
            isAutoClose={true}
            variant='white-1'
            classes='width-max-content'
            menuClasses={'width-max-content'}
          />
        ))}
      </div>
      <SubmissionFilters
        allQuestions={allQuestions}
        versionsList={versionsList}
        filterData={filterData}
        setFilterData={setFilterData}
        showAttachment={showAttachment}
        setShowAttachment={setShowAttachment}
        queryFilter={queryFilter}
        setQueryFilter={setQueryFilter}
        searchState={searchState}
        handleSearchStateChange={handleSearchStateChange}
        selectedRows={selectedRows}
        actionsCallBack={actionsCallBack}
      />

      {loader && <Loader color='green-1' />}
      <div className='d-flex justify-content-end submissions'>
        <div className={`table-responsive`}>
          <TableComponent
            header={submissionsHeaderList}
            data={submissionsList}
            props={{
              type: 'submissions',
              submissionsMediaObject:
                showAttachment === 'attachment' ? submissionsMediaObject : null,
              mediaEnabled: showAttachment === 'attachment',
              isSupervisorColumn: true,
              actionsType: 'submission_actions',
              setTableHeaderChange,
              filteringHeader: submissionsHeaderList,
              actionsCallBack: RowClickCallBackAction,
              statusChangeCallBack,
            }}
            totalRecords={totalRecords}
            filterEnabled={false}
            loader={loader}
            isFieldSelectionEnabled={false}
            isPaginationEnabled={false}
            isActionsEnabled={false}
            isSelectionEnabled={true}
            paginationFunction={getFormSubmissions}
            selectionKey='form_submission_id'
            onSelection={onRowsSelection}
            selectedRows={selectedRows}
            maintainTableMaxHeight
            setScrollTop={setScrollTop}
            filterData={filterData}
          />
        </div>
      </div>
      <Pagination
        totalRecords={totalRecords}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        displayEntries={displayEntries}
        setDisplayEntries={changeDisplayEntries}
        currentPageRecords={submissionsList ? submissionsList.length : 0}
        paginationFunction={getFormSubmissions}
      />

      {addSubmission && (
        <AddSubmission
          title={'Submission'}
          supervisorList={supervisorList}
          onSubmitForm={submitDataForm}
          loading={submissionLoader}
          values={editedSubmissionValues}
          handleClose={() => {
            setAddSubmission(false);
          }}
        />
      )}
    </div>
  );
}

const StatusField = ({
  formSubmissionId,
  formVersionId,
  formId,
  formName,
  valuesList,
  getFormSubmissions,
  isTestSubmission,
  approvalStatus,
}) => {
  const [show, setShow] = useState(false);
  const valuesMap = Object.fromEntries(valuesList.map((v) => [v.value, v.responseText]));

  const changeSubmissionStatus = async (value) => {
    const result = await CallAPI('UPDATE_FORM_STATUS_FIELD', {
      form: { id: formId, title: formName },
      formVersion: formVersionId,
      submission: { id: formSubmissionId, title: formName },
      approvalStatus: value,
      locale: 'en',
      isTestSubmission,
    });
    if (result.status) {
      getFormSubmissions();
      toast.success('Record status updated successfully');
    } else {
      getFormSubmissions();
      toast.error(result.error);
    }
    setShow(false);
  };

  const submissionStatusPopover = (
    <Popover className='min-width-176 overflow-hidden' id='popover-basic'>
      <Popover.Body className='p-0'>
        {valuesList.map((l, index) => (
          <div
            className={`dropdown-item cursor-pointer px-3${
              approvalStatus === l.value ? ' selected' : ''
            }`}
            key={index}
            onClick={() => changeSubmissionStatus(l.value)}
          >
            <span>{l.responseText}</span>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  const variant = getApprovalStatusVariant(approvalStatus);

  return (
    <OverlayTrigger
      trigger='click'
      onToggle={() => setShow(!show)}
      show={show}
      placement='bottom-end'
      rootClose
      overlay={submissionStatusPopover}
    >
      <div className={`d-flex custom-form-button theme-button-${variant}`}>
        <span className={variant !== 'white-1' ? 'theme-text-white-1' : ''}>
          {valuesMap[approvalStatus] ?? 'Select'}
        </span>
        <img className={variant !== 'white-1' ? 'svg-white' : ''} src={selectArrow} />
      </div>
    </OverlayTrigger>
  );
};
StatusField.propTypes = {
  formSubmissionId: PropTypes.string.isRequired,
  formVersionId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  valuesList: PropTypes.array.isRequired,
  getFormSubmissions: PropTypes.func.isRequired,
  isTestSubmission: PropTypes.bool.isRequired,
  approvalStatus: PropTypes.string.isRequired,
};

export default Submissions;
