/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import NumberFieldWithTag from 'components/form-components/NumberFieldWithTag';
import TextField from 'components/form-components/TextField';
import ColorChangeModal from 'components/common-components/ColorChangeModal';
import FormButton from 'components/form-components/FormButton';
import editIcon from 'assets/images/icons/edit.svg';
import DateFieldWithTag from 'components/form-components/DateFieldWithTag';
import SelectField from 'components/form-components/SelectField';
import ColorField from 'components/form-components/ColorField';
import SelectIconField from 'components/form-components/SelectIconField';
import RatingBox from './common-components/RatingBox';
import NumberField from 'components/form-components/NumberField';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import ChoiceListOptionList from './choiceListBasicProperties/ChoiceListOptionList';
import DataSourceBasicProperties from './dataSourceBasicProperties/DataSourceBasicProperties';
import ImageFilePicker from './ImageFilePicker';
import ExternalApiBasicProperties from './externalApi/ExternalApiBasicProperties';
import { validateSqlColumnName } from 'pages/forms/utils';
import watermarkBottomIcon from 'assets/images/icons/watermark-bottom.svg';
import watermarkTopIcon from 'assets/images/icons/watermark-top.svg';
import IconSelectionField from 'components/form-components/IconSelectionField';
import CustomMentionInput from 'components/form-components/CustomMentionInput';

function SingleFieldProperties({
  onChangeProperties,
  singleProperty,
  fieldSectionProperties,
  propertiesList,
  otherDetails,
  toggleBox,
  dataSourcesList,
  selectedField,
  hasTriggers,
}) {
  const { t } = useTranslation();
  const [answerChoiceToggle, setAnswerChoiceToggle] = useState(false);
  const [openColorModal, setOpenColorModal] = useState(false);
  const [openColorTypeModal, setOpenColorTypeModal] = useState('');

  const iconOptions = [
    { value: 'bottom', icon: watermarkBottomIcon },
    { value: 'top', icon: watermarkTopIcon },
  ];

  const allFormElemetsOptionArray = Object.values(otherDetails.allFormElementsObj).map(el => ({
    display: el.fieldName,
    value: el.fieldId,
    type: 'option'
  }));
  const systemElementsOptionArray = [
    { display: 'User Name', value: 'userName', type: 'option'},
    { display: 'Date And Time', value: 'dateAndTime', type: 'option'},
  ];
  const mentionsOptionArray = [
    { display: 'Please Select Contents:', value: 'Please Select Contents:', type: 'heading'},
    { display: 'Options:', value: 'Options:', type: 'heading'},
    ...systemElementsOptionArray,
    { display: 'Fields ID\'s:', value: 'Fields ID\'s:', type: 'heading'},
    ...allFormElemetsOptionArray
  ];
  console.log("pkop: ", 
  mentionsOptionArray
  );

  return (
    <div
      className={`${
        singleProperty.externalCssApply ? 'flex-fill d-flex align-items-end' : 'w-100'
      } ${
        singleProperty.key === 'transparent' || singleProperty.key === 'withoutFrame' ? 'pb-2' : ''
      } ${singleProperty.classes ? singleProperty.classes : ''}`}
    >
      {singleProperty.type === 'toggle' && singleProperty.key === 'optionList' ? (
        <ToggleBox>
          <ToggleBoxHeader
            show={answerChoiceToggle}
            toggleBoxOpen={() => setAnswerChoiceToggle(!answerChoiceToggle)}
            title={t(singleProperty.text)}
          />
          <ToggleBoxBody show={answerChoiceToggle}>
            <ChoiceListOptionList
              setName={fieldSectionProperties.setName}
              optionList={fieldSectionProperties[singleProperty.key]}
              onChangeBasicProperties={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}
            />
          </ToggleBoxBody>
        </ToggleBox>
      ) : singleProperty.type === 'toggle' && singleProperty.key === 'dataSource' ? (
        <DataSourceBasicProperties
          dataSourcesList={dataSourcesList}
          otherDetails={otherDetails}
          properties={fieldSectionProperties}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
        />
      ) : singleProperty.type === 'toggle' && singleProperty.key === 'externalApi' ? (
        <ExternalApiBasicProperties
          otherDetails={otherDetails}
          properties={fieldSectionProperties}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
        />
      ) : singleProperty.type === 'paragraph' ? (
        <span>{t(singleProperty.text)}</span>
      ) : singleProperty.type === 'checkbox' ? (
        <>
          <SingleCheckBoxField
            disabled={
              (singleProperty.disabledOn?.length > 0
                ? singleProperty.disabledOn.some(
                    (key) => fieldSectionProperties[key] === singleProperty.disabledCondition,
                  )
                : false) || hasTriggers
            }
            name={singleProperty.key}
            value={fieldSectionProperties[singleProperty.key]}
            handleChange={(name, value) => {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }}
            text={<span>{t(singleProperty.text)}</span>}
            shrink
          />
          {singleProperty.dependantElementsList && fieldSectionProperties[singleProperty.key] && (
            <div
              className={`d-flex mt-3 gap-2 ms-4 flex-wrap align-items-center dependentsList ${
                singleProperty.text === 'form_field_date_range' ? 'flex-column' : ''
              } `}
            >
              {singleProperty.dependantElementsList.map((singleDependantField, index) => {
                if (
                  (singleDependantField.isAllowedForAll &&
                    singleDependantField.restrictedFieldTypes.indexOf(selectedField.elementType) ===
                      -1) ||
                  singleDependantField.allowedFieldTypes.indexOf(selectedField.elementType) > -1
                ) {
                  return (
                    <SingleFieldProperties
                      key={'dep' + index}
                      onChangeProperties={onChangeProperties}
                      singleProperty={singleDependantField}
                      fieldSectionProperties={fieldSectionProperties}
                      toggleBox={toggleBox}
                      propertiesList={propertiesList}
                      otherDetails={otherDetails}
                      dataSourcesList={[]}
                      selectedField={selectedField}
                    />
                  );
                }
              })}
            </div>
          )}
        </>
      ) : singleProperty.type === 'dateWithTag' ? (
        <DateFieldWithTag
          name={singleProperty.key}
          tagText={t(singleProperty.text)}
          value={fieldSectionProperties[singleProperty.key]}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
          shrink
        />
      ) : singleProperty.type === 'numberWithTag' ? (
        <NumberFieldWithTag
          name={singleProperty.key}
          tagText={t(singleProperty.text)}
          value={fieldSectionProperties[singleProperty.key]}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
          shrink
        />
      ) : singleProperty.type === 'mentionsText' ? (
        <div
          // className={`custom-input-box${classes ? ' ' + classes : ''} ${
          //   isHorizontal ? 'd-flex align-items-center w-100' : ''
          // } ${isDisplayAbleOnly ? 'read-only' : ''}`}
        >
          {console.log("plpld: ",
            // onChangeProperties,
            singleProperty,
            singleProperty.watermarkUsedFieldsMap,
            fieldSectionProperties.watermarkUsedFieldsMap,
            // propertiesList,
            // otherDetails.allFormElementsObj,
            // toggleBox,
            // dataSourcesList,
            // selectedField,
            // hasTriggers
          )}

          <CustomMentionInput 
            rows={4}
            // displayName={singleProperty.text}
            value={fieldSectionProperties[singleProperty.key]}
            name={singleProperty.key}
            UsedFieldsMap={fieldSectionProperties.watermarkUsedFieldsMap}
            // handleChange={handleChange}
            handleChange={(name, value) => {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }}
            disabled={
              singleProperty.disabledOn
                ? fieldSectionProperties[singleProperty.disabledOn].toString() ===
                  singleProperty.disabledCondition.toString()
                : false
            }
            placeholder={t('Write')}
            classes='w-100'
            mentionsOptions={mentionsOptionArray}
          />

          <div className='ms-3'>
            <IconSelectionField
              name={singleProperty.watermarkAlignment}
              value={fieldSectionProperties[singleProperty.watermarkAlignment]}
              handleChange={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}

              iconOptions={iconOptions}
              // styleProperties={styleProperties}
              styleProperties={{ [`${'fontField'}Color`]: 'red' }}
              // classes='ms-3'
            />

            {console.log("btrc: ", singleProperty, fieldSectionProperties, fieldSectionProperties[singleProperty.watermarkTextCenter])}
            <SingleCheckBoxField
              // name={singleProperty.key}
              name={singleProperty.watermarkTextCenter}
              classes='mt-2'
              // value={fieldSectionProperties[singleProperty.key]}
              value={fieldSectionProperties[singleProperty.watermarkTextCenter]}
              handleChange={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}
              // text={<span>{t(singleProperty.text)}</span>}
              text={<span>{t('Show Text on Center')}</span>}
              shrink
            />
          </div>


        </div>
      ) : singleProperty.type === 'imagePicker' ? (
        <ImageFilePicker
          properties={fieldSectionProperties}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
        />
      ) : singleProperty.type === 'text' ? (
        <TextField
          disabled={
            singleProperty.disabledOn
              ? fieldSectionProperties[singleProperty.disabledOn].toString() ===
                singleProperty.disabledCondition.toString()
              : false
          }
          name={singleProperty.key}
          value={fieldSectionProperties[singleProperty.key]}
          displayName={singleProperty.text}
          handleChange={(name, value) => {
            if (name === 'fieldUniqueId') {
              if (validateSqlColumnName(value))
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            } else {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }
          }}
          shrink
        />
      ) : singleProperty.type === 'ColorPicker' ? (
        <ColorField
          name={singleProperty.key}
          displayName={t(singleProperty.text)}
          value={fieldSectionProperties[singleProperty.key + 'Color']}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
          shrink
        />
      ) : singleProperty.type === 'slider' ? (
        <>
          <label className='theme-size-0_8'>{t('form_choose_number_of_range')}</label>
          <div className='d-flex gap-2 align-items-center mb-3'>
            <NumberField
              name={singleProperty.minRangeKey}
              value={fieldSectionProperties[singleProperty.minRangeKey]}
              handleChange={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}
              shrink
            />
            <span>{t('form_to')}</span>
            <NumberField
              name={singleProperty.maxRangeKey}
              value={fieldSectionProperties[singleProperty.maxRangeKey]}
              handleChange={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}
              shrink
            />
          </div>
        </>
      ) : singleProperty.type === 'rating' ? (
        <>
          <label className='theme-size-0_8'>{t('form_field_number_of_rating')}</label>
          <div className='d-flex gap-2 align-items-center mb-3'>
            <TextField
              name={singleProperty.lowLabelKey}
              value={fieldSectionProperties[singleProperty.lowLabelKey]}
              handleChange={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}
              shrink
            />
            <RatingBox
              rating={fieldSectionProperties[singleProperty.key]}
              changeRating={(value) => {
                if (value > 1 && value < 10)
                  onChangeProperties(
                    singleProperty.key,
                    value,
                    toggleBox.defaultPropsKey + 'Properties',
                  );
              }}
            />
            <TextField
              name={singleProperty.highLabelKey}
              value={fieldSectionProperties[singleProperty.highLabelKey]}
              handleChange={(name, value) => {
                onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
              }}
              shrink
            />
          </div>
        </>
      ) : singleProperty.type === 'dropdownIcon' ? (
        <SelectIconField
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
          name={singleProperty.key}
          placeholder={t(singleProperty.key)}
          label={fieldSectionProperties[singleProperty.key]}
          icon={singleProperty.icon}
          variant='white-1'
          isAutoClose={true}
          iconType='rating'
        />
      ) : singleProperty.type === 'dropdown' ? (
        <SelectField
          placeholder={singleProperty.text}
          label={
            singleProperty.valuesList.filter(
              (singleValue) => singleValue.value === fieldSectionProperties[singleProperty.key],
            )[0].displayValue
          }
          listValues={singleProperty.valuesList}
          icon={singleProperty.icon}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
          selectedValues={[]}
          name={singleProperty.key}
          isAutoClose={true}
          variant='white-1'
          menuClasses={'w-100'}
          shrink
          filedClasses='w-100'
        />
      ) : singleProperty.type === 'textWithColor' ? (
        <div className='d-flex gap-2 align-items-end'>
          <TextField
            name={singleProperty.key}
            value={fieldSectionProperties[singleProperty.key]}
            displayName={singleProperty.text}
            handleChange={(name, value) => {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }}
            shrink
          />
          <div className='position-relative'>
            <FormButton
              text='button_edit'
              variant='green-1'
              icon={editIcon}
              iconRight
              minWidth
              classes='px-2'
              onClick={() => {
                setOpenColorTypeModal(singleProperty.key);
                setOpenColorModal(true);
              }}
            />
            {openColorModal && openColorTypeModal === singleProperty.key && (
              <ColorChangeModal
                name={singleProperty.key}
                closeModal={() => setOpenColorModal(false)}
                styleProperties={propertiesList[toggleBox.defaultPropsKey + 'Properties'] ?? {}}
                handleChange={(name, value) => {
                  onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
                }}
              />
            )}
          </div>
        </div>
      ) : (
        singleProperty.type
      )}
    </div>
  );
}
SingleFieldProperties.propTypes = {
  onChangeProperties: PropTypes.func.isRequired,
  singleProperty: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  fieldSectionProperties: PropTypes.object.isRequired,
  toggleBox: PropTypes.object.isRequired,
  propertiesList: PropTypes.object.isRequired,
  selectedField: PropTypes.object.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  hasTriggers: PropTypes.bool,
};
export default SingleFieldProperties;
