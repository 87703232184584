import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import AlertModal from 'components/common-components/AlertModal';
import BindingComponent from 'pages/form/BindingComponent';
import ExternalApiFields from 'pages/form/externalApi/ExternalApiFields';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { validateNestedExportProperties } from './utils';
import { FormExportSchema } from '../utils';
import { CallAPI } from 'actions/General';

const ExternalDBFormExport = ({ updateTab, formDetails, getFormDetail }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    formId: '',
    apiMethod: 'post',
    // apiParamType: '',
    // apiParamValue: '',
    apiUrl: '',
    bindingList: [],
    bindingCustomList: [],
    systemBindingList: [],
    mediaBindingList: [],
    auth: {
      authType: 'bearer',
      authValue: '',
      authApiUrl: '',
      authApiMethod: 'post',
      responseAuthKeyName: '',
      parameters: { client_name: '', client_id: '', client_secret: '' },
    },
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [otherDetails, setOtherDetails] = useState({
    allFormElementsObj: {},
  });

  const systemBindingElements = [
    { fieldName: 'system_field_user_name', fieldId: 'user_name' },
    { fieldName: 'system_field_submit_date', fieldId: 'submit_date' },
    { fieldName: 'system_field_submission_status', fieldId: 'submission_status' },
    { fieldName: 'system_field_pdf_report_url', fieldId: 'pdf_report_url' },
    { fieldName: 'system_field_form_name', fieldId: 'form_name' },
    { fieldName: 'system_field_reference_number', fieldId: 'reference_number' },
  ];
  const otherDetailsSystem = {
    allFormElementsObj: Object.fromEntries(systemBindingElements.map((b) => [b.fieldId, b])),
  };

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const handleBindingListChange = (field, value) => {
    if (field === 'removeBindingItem') {
      const tempList = [...values[value.bindingKey]];
      tempList.splice(value.index, 1);
      setValues({ ...values, [value.bindingKey]: tempList });
    } else {
      const tempList = [...values[field], { ...value }];
      setValues({ ...values, [field]: tempList });
    }
  };

  const submit = async () => {
    setLoading(true);
    setTouched({});
    let nestedErrors = null;
    try {
      nestedErrors = validateNestedExportProperties(values);
      await FormExportSchema.validate(values, { abortEarly: false });
      // if passes schema validations
      if (nestedErrors) {
        setErrors({ ...nestedErrors });
        setLoading(false);
      } else {
        const payload = {
          requestType: 'api',
          apiMethod: values.apiMethod,
          apiUrl: values.apiUrl,
          auth: {
            ...cloneDeep(values.auth),
          },
          bindingList: {
            ...Object.fromEntries(
              values.bindingList.map(({ fieldId, columnId, ...l }) => [
                fieldId,
                { key: columnId, ...l },
              ]),
            ),
            // adding "customValue" property for "bindingCustomList" and assigning "fieldId" to it
            ...Object.fromEntries(
              values.bindingCustomList.map(({ fieldId, columnId, ...l }, i) => [
                `${Date.now() + i}`,
                { key: columnId, customValue: fieldId, ...l },
              ]),
            ),
            ...Object.fromEntries(
              values.systemBindingList.map(({ fieldId, columnId, ...l }) => [
                fieldId,
                { key: columnId, ...l },
              ]),
            ),
          },
          mediaBindingList: {
            ...Object.fromEntries(
              values.mediaBindingList.map(({ fieldId, columnId, ...l }) => [
                fieldId,
                { key: columnId, ...l },
              ]),
            ),
          },
        };

        const result = await CallAPI(
          'SAVE_FORM',
          { formId: formDetails._id, externalExportSettings: [payload] },
          null,
          setLoading,
          null,
          null,
        );

        if (result.status) {
          setShowSuccessAlert(true);
          getFormDetail(formDetails._id);
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors({ ...(nestedErrors ?? {}), ...schemaErrors });
      setLoading(false);
    }
  };

  useEffect(() => {
    let allFormElementsObj = {};
    let bindingElementsObj = {};
    if (formDetails.currentVersion?.elements) {
      formDetails.currentVersion.elements.map((singleElement) => {
        let properties = JSON.parse(singleElement.properties);
        if (!['page', 'section', 'image', 'dataSource'].includes(singleElement.elementType)) {
          allFormElementsObj = {
            ...allFormElementsObj,
            [singleElement.elementGlobalId]: {
              fieldName: properties.basicProperties.fieldTitle,
              fieldId: singleElement.elementGlobalId,
              fieldType: 'element',
            },
          };
        }

        if (singleElement.elementType === 'externalApi') {
          const currentElementBinding = properties.basicProperties.bindingList;
          if (currentElementBinding.length) {
            currentElementBinding.map((binding) => {
              if (binding && binding.fieldId)
                bindingElementsObj = {
                  ...bindingElementsObj,
                  [binding.fieldId]: {
                    fieldName: properties.basicProperties.fieldTitle,
                    fieldId: singleElement.elementGlobalId,
                  },
                };
            });
          }
        }
      });

      formDetails.currentVersion.supervisorElements.map((singleElement) => {
        let properties = JSON.parse(singleElement.properties);
        if (!['status', 'section'].includes(singleElement.elementType)) {
          allFormElementsObj = {
            ...allFormElementsObj,
            [singleElement.elementGlobalId]: {
              fieldName: properties.basicProperties.fieldTitle,
              fieldId: singleElement.elementGlobalId,
              fieldType: 'supervisor',
            },
          };
        }
      });
    }
    setOtherDetails({ bindingElementsObj, allFormElementsObj, hasStatusFieldInForm: false });
    if (formDetails.externalExportSettings?.length) {
      const exportSetting = formDetails.externalExportSettings[0];

      let bindingList = [];
      let bindingCustomList = [];
      let systemBindingList = [];
      if (exportSetting.bindingList)
        Object.entries(exportSetting.bindingList).map(([fieldId, obj]) => {
          const { key, dataType, allowMultiple, type, customValue } = obj;
          if (type === 'element' || type === 'supervisor') {
            bindingList.push({ fieldId, columnId: key, dataType, allowMultiple, type });
          } else if (type === 'custom') {
            // fetching "fieldId" for type="custom" from inner "obj"
            bindingCustomList.push({
              fieldId: customValue,
              columnId: key,
              dataType,
              allowMultiple,
              type,
            });
          } else {
            systemBindingList.push({ fieldId, columnId: key, dataType, allowMultiple, type });
          }
        });

      const mediaBindingList = exportSetting.mediaBindingList
        ? Object.entries(exportSetting.mediaBindingList).map(([fieldId, obj]) => {
            const { key, dataType, allowMultiple, type } = obj;
            return { fieldId, columnId: key, dataType, allowMultiple, type };
          })
        : [];

      setValues({
        ...values,
        requestType: exportSetting.requestType,
        method: exportSetting.method,
        apiUrl: exportSetting.apiUrl ?? '',
        auth: { ...cloneDeep(exportSetting.auth) },
        bindingList,
        bindingCustomList,
        systemBindingList,
        mediaBindingList,
      });
    }
  }, [formDetails]);

  return (
    <div className='p-3'>
      <AlertModal
        show={showSuccessAlert}
        showCloseButton={true}
        closeModal={() => setShowSuccessAlert(false)}
        title='alert_success'
        message={'alert_form_export_setting_updated'}
        variant='success'
        actionButtons={[
          { text: t('button_ok'), variant: 'green-1', onClick: () => setShowSuccessAlert(false) },
        ]}
      />
      <div className='d-flex flex-column gap-3'>
        <ExternalApiFields
          properties={values}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          isHorizontal
          type='form-external-api'
        />

        <BindingComponent
          title='form_bind_field_to_external_api'
          properties={values}
          handleChange={handleBindingListChange}
          otherDetails={otherDetails}
          bindingKey='bindingList'
          type='form-external-api'
        />

        <BindingComponent
          title='form_bind_custom_value_to_external_api'
          properties={values}
          handleChange={handleBindingListChange}
          otherDetails={otherDetails}
          bindingKey='bindingCustomList'
          type='form-external-api'
        />

        <BindingComponent
          title='form_bind_system_field_to_external_api'
          properties={values}
          handleChange={handleBindingListChange}
          otherDetails={otherDetailsSystem}
          bindingKey='systemBindingList'
          type='form-external-api'
        />

        <BindingComponent
          title='form_bind_field_media_to_external_api'
          properties={values}
          handleChange={handleBindingListChange}
          otherDetails={otherDetails}
          bindingKey='mediaBindingList'
          type='form-external-api'
        />

        <div className='w-100 d-flex justify-content-end gap-3'>
          <FormButton text='button_cancel' variant='white-1' onClick={() => updateTab(0)} />
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            loading={loading}
            onClick={submit}
          />
        </div>
      </div>
    </div>
  );
};

ExternalDBFormExport.propTypes = {
  updateTab: PropTypes.func,
  formDetails: PropTypes.object.isRequired,
  getFormDetail: PropTypes.func,
};

export default ExternalDBFormExport;
