import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import backArrow from 'assets/images/icons/arrow-left.svg';
import SelectionComponent from '../../../components/common-components/SelectionComponent';
import { useTranslation } from 'react-i18next';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import AlertModal from 'components/common-components/AlertModal';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import FormButton from 'components/form-components/FormButton';
import sectionGreyIcon from 'assets/images/icons/section-grey.svg';

const UpdateSectionPermissions = ({ formDetails, selectedPermission, goBack, getFormDetail }) => {
  const currentSections = formDetails.currentVersion.supervisorElements.filter(
    (element) => element.elementType === 'section',
  );

  const currentSectionIds = currentSections.map((element) => element.elementGlobalId);

  const savedSectionPermissionIds = selectedPermission.sections.map(
    (section) => section?.managerSectionGlobalId,
  );

  const newAddedSections = currentSections
    .filter((sectionElement) => !savedSectionPermissionIds.includes(sectionElement.elementGlobalId))
    .map((element) => ({
      managerSectionGlobalId: element.elementGlobalId,
      sectionTitle: JSON.parse(element.properties).basicProperties.sectionTitle,
      users: [],
      groups: [],
    }));

  const allSavedPermissions = [
    ...selectedPermission.sections
      .filter((section) => currentSectionIds.includes(section?.managerSectionGlobalId))
      .map((section) => ({
        managerSectionGlobalId: section.managerSectionGlobalId,
        sectionTitle: section.sectionTitle,
        users: (section?.users ?? []).map((user) => ({
          id: user.userId._id,
          name: user.userId.firstName + ', ' + user.userId.lastName,
          accessLevel: user.accessLevel,
        })),
        groups: (section?.groups ?? []).map((group) => ({
          id: group.groupId._id,
          name: group.groupId.groupName,
          accessLevel: group?.accessLevel,
        })),
      })),
    ...newAddedSections,
  ];

  const { t } = useTranslation();
  const [groupBtnLoading, setGroupBtnLoading] = useState(false);
  const [userBtnLoading, setUserBtnLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  // const [selectedFolders] = useState(
  //   (selectedPermission[0].folders ?? []).map((folder) => ({ id: folder._id, name: folder.name })),
  // );
  const [showAlert, setShowAlert] = useState(false);

  const [errors, setErrors] = useState({ users: null, groups: null });
  const [APIError, setAPIError] = useState('');

  const [updatedSectionsList, setUpdatedSectionsList] = useState(allSavedPermissions);

  const managerSectionList = useRef(
    currentSections.map((element) => ({
      elementGlobalId: element.elementGlobalId,
      sectionTitle: JSON.parse(element.properties).basicProperties.sectionTitle,
    })),
  );

  const [activeManagerSection, setActiveManagerSection] = useState(
    currentSections[0]?.elementGlobalId || '',
  );

  const isInitialRender = useRef(true);

  useEffect(() => {
    const selectedSectionElement = managerSectionList.current.find(
      (section) => section?.elementGlobalId === activeManagerSection,
    );

    const index = updatedSectionsList.findIndex(
      (section) => section.managerSectionGlobalId === selectedSectionElement.elementGlobalId,
    );

    if (!isInitialRender.current) {
      if (index !== -1) {
        const updatedSectionPermission = {
          ...updatedSectionsList[index],
          users: selectedUsers,
          groups: selectedGroups,
        };
        setUpdatedSectionsList(
          updatedSectionsList.map((section, jIndex) => {
            if (jIndex === index) return updatedSectionPermission;
            return section;
          }),
        );
      } else {
        const newItem = {
          managerSectionGlobalId: selectedSectionElement?.elementGlobalId,
          sectionTitle: selectedSectionElement?.sectionTitle,
          users: selectedUsers,
          groups: selectedGroups,
        };

        setUpdatedSectionsList((prevList) => [...prevList, newItem]);
      }
    }

    if (isInitialRender.current) isInitialRender.current = false;
  }, [selectedUsers, selectedGroups]);

  useEffect(() => {
    const index = updatedSectionsList.findIndex(
      (section) => section.managerSectionGlobalId === activeManagerSection,
    );

    if (index !== -1) {
      const updatedUsers = (updatedSectionsList[index]?.users ?? []).map((user) => ({
        id: user.id,
        name: user.name,
        accessLevel: user.accessLevel,
      }));

      const updatedGroups = (updatedSectionsList[index]?.groups ?? []).map((group) => ({
        id: group.id,
        name: group.name,
        accessLevel: group.accessLevel,
      }));

      setSelectedUsers(updatedUsers);

      setSelectedGroups(updatedGroups);
    } else {
      setSelectedUsers([]);
      setSelectedGroups([]);
    }
  }, [activeManagerSection]);

  const submit = async (ids, type) => {
    if (ids.length <= 0) {
      setErrors({ ...errors, [type]: 'Selected list can not be empty' });
      return;
    }

    const modifiedFinalList = updatedSectionsList.map((section) => ({
      ...section,
      users: section.users.map((user) => ({ userId: user.id, accessLevel: user.accessLevel })),
      groups: section.groups.map((group) => ({
        groupId: group.id,
        accessLevel: group.accessLevel,
      })),
    }));

    let values = {
      formId: formDetails._id,
      [selectedPermission.key]: modifiedFinalList,
    };

    let result = await CallAPI(
      'SAVE_FORM',
      values,
      null,
      type === 'users' ? setUserBtnLoading : setGroupBtnLoading,
      setAPIError,
      null,
    );

    if (result.status) {
      setShowAlert(true);
      getFormDetail(formDetails._id);

      setErrors({
        users: null,
        groups: null,
      });
    }
  };

  return (
    <div className=''>
      {showAlert && (
        <AlertModal
          show={showAlert}
          showCloseButton={true}
          closeModal={() => setShowAlert(false)}
          title='alert_success'
          message='alert_permission_updated'
          variant='success'
          actionButtons={[{ text: t('button_ok'), variant: 'green-1', onClick: () => goBack() }]}
        />
      )}
      <div className='row head'>
        <div className='col-4 d-flex align-items-center cursor-pointer' onClick={goBack}>
          <img src={backArrow} className='me-2' />
          <label className='theme-text-black-1'>{t('text_back')}</label>
        </div>
        <div className='col-4 title text-center'>
          {t(
            selectedPermission.key === 'appUser'
              ? 'text_app_users_are_allowed_to_use_this_form_on_mobile_app'
              : selectedPermission.key === 'supervisor' ||
                selectedPermission.key === 'managerSections'
              ? 'text_supervisors_are_allowed_to_view_data_on_web'
              : 'text_designers_are_allowed_to_make_changes_on_web',
          )}
        </div>
      </div>
      {APIError && <Error msg={APIError} />}
      {selectedPermission.key === 'managerSections' && managerSectionList.current.length > 0 && (
        <div className='mb-3 mt-2'>
          <ul className='d-flex list-unstyled pb-1 m-0 overflow-auto'>
            {managerSectionList.current.map((element, i) => (
              <li
                className='d-flex align-items-center me-3 text-nowrap'
                onClick={() => setActiveManagerSection(element.elementGlobalId)}
                key={i}
              >
                <span
                  className={`fw-normal ms-2 cursor-pointer py-2 px-3 border-bottom-grey-10-h-2 d-flex gap-2 ${
                    activeManagerSection === element.elementGlobalId
                      ? ` theme-background-green-1 text-white rounded-top border-bottom-green-1-h-2`
                      : ''
                  }`}
                >
                  <img
                    className={activeManagerSection === element.elementGlobalId ? 'svg-white' : ''}
                    src={sectionGreyIcon}
                  />
                  {element.sectionTitle}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedPermission.key === 'managerSections' && managerSectionList.current.length > 0 && (
        <>
          <SelectionComponent
            title='text_groups'
            type='groups'
            submit={(ids) => {
              submit(ids, 'groups');
            }}
            selectedDataList={selectedGroups}
            setSelectedList={setSelectedGroups}
            loading={groupBtnLoading}
            error={errors.groups}
            showButtons={false}
            accessLevelKey={selectedPermission.key}
          />
          <SelectionComponent
            classes='mt-4'
            title='text_users'
            // onCancel={cancelUser}
            submit={(ids) => {
              submit(ids, 'users');
            }}
            selectedDataList={selectedUsers}
            setSelectedList={setSelectedUsers}
            loading={userBtnLoading}
            error={errors.users}
            // buttonsPosition='bottom'
            showButtons={false}
            accessLevelKey={selectedPermission.key}
          />
          {/* <SelectionComponent
        classes='mt-4'
        title='Folders'
        type='folders'
        onCancel={cancelFolder}
        submit={(ids) => {
          submit(ids, 'folders');
        }}
        selectedDataList={selectedFolders}
        setSelectedList={setSelectedFolders}
        loading={groupBtnLoading}
        error={errors.users}
        buttonsPosition='bottom'
      /> */}

          <div className='w-100 d-flex justify-content-end gap-3 mt-4'>
            <FormButton text='button_cancel' variant='white-1' onClick={goBack} />
            <FormLoadingButton
              text='button_save'
              variant='green-1'
              onClick={submit}
              loading={userBtnLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

UpdateSectionPermissions.propTypes = {
  formDetails: PropTypes.object.isRequired,
  selectedPermission: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  getFormDetail: PropTypes.func.isRequired,
};

export default UpdateSectionPermissions;
