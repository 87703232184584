import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const IconSelectionField = ({ name, value, handleChange, classes, iconOptions }) => {
  const { t } = useTranslation();

  return (
    <div className={`w-100 ${classes}`}>
      <label className='theme-size-0_8'>{t('Watermark Alignment')}</label>
      <div className='font-type-field d-flex gap-2'>
        {iconOptions.map((item, index) => (
          <div
            key={index}
            className={`d-flex align-self-center rounded-circle ${
              value === item.value ? 'theme-background-green-1 active' : ''
            }`}
            onClick={() => handleChange(name, item.value)}
          >
            <img className={value === item.value ? 'svg-white' : 'svg-black'} src={item.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

IconSelectionField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  iconOptions: PropTypes.array.isRequired,
};

export default IconSelectionField;
