import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormButton from 'components/form-components/FormButton';
import SelectField from 'components/form-components/SelectField';
import trashIcon from 'assets/images/icons/trash-grey.svg';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import toast from 'react-hot-toast';
import TextField from 'components/form-components/TextField';
import { DataTypeValues } from './utils';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

function BindingComponent({
  title,
  properties,
  handleChange,
  otherDetails,
  columnValueList,
  bindingKey = 'bindingList', //default key for this array
  type,
}) {
  const { t } = useTranslation();

  const [toggler, setToggler] = useState(false);
  const initialValues = {
    fieldId: '',
    columnId: '',
    dataType: 'string',
    allowMultiple: false,
    type: bindingKey === 'systemBindingList' ? 'system' : bindingKey === 'bindingCustomList' ? 'custom' : 'element',
  };
  const [values, setValues] = useState({ ...initialValues });
  const [userElementsList, setUsersElementsList] = useState([]);
  const handleChangeValues = (field, value) => {
    if (field === 'fieldId' && type === 'form-external-api' && bindingKey === 'bindingList') {
      let type = otherDetails.allFormElementsObj[value].fieldType;
      setValues({ ...values, [field]: value, type });
    } else setValues({ ...values, [field]: value });
  };

  const removeFromList = (index) => {
    const tempList = [...properties[bindingKey]];
    const removedFieldId = tempList[index].fieldId;

    // last parameter bindingKey is only for form export
    handleChange('removeBindingItem', { index, removedFieldId, bindingKey });
  };
  const columnValuesMap = columnValueList
    ? Object.fromEntries(columnValueList.map((v) => [v.value, v.displayValue]))
    : null;

  const addToList = () => {
    // dont check fieldId duplicate validation when bindingKey = "bindingCustomList"
    const foundBinding = properties[bindingKey].find(
      (binding) => binding.fieldId === values.fieldId && bindingKey!=='bindingCustomList' ,
    );
    if (foundBinding) {
      toast.error(`Already bind to ${foundBinding.fieldName} field`);
    } else {
      handleChange(bindingKey, { ...values });
      setValues({ ...initialValues });
    }
  };

  useEffect(() => {
    let formElementsList = [];
    Object.values(otherDetails.allFormElementsObj).map((singleFormElement) => {
      formElementsList.push({
        value: singleFormElement.fieldId,
        displayValue: singleFormElement.fieldName,
      });
    });
    setUsersElementsList(formElementsList);
  }, [otherDetails.allFormElementsObj]);

  const userElementsListMap = Object.fromEntries(
    userElementsList.map((v) => [v.value, v.displayValue]),
  );

  const dataTypeValuesMap = Object.fromEntries(
    DataTypeValues.map((v) => [v.value, v.displayValue]),
  );

  return (
    <ToggleBox classes='mt-3'>
      <ToggleBoxHeader
        show={toggler}
        toggleBoxOpen={() => {
          if (properties.dataSource !== '') setToggler(!toggler);
        }}
        title={title ?? 'form_bind_field_to_data_source'}
      />
      <ToggleBoxBody show={toggler}>
        <Fragment>
          <div className='common-list-view'>
            <table className='theme-font-inter-regular theme-size-0_9'>
              <thead>
                <tr>
                  <th>{bindingKey === 'bindingCustomList' ? t('form_value') : t('form_field_field_id')}</th>
                  <th>
                    {t(columnValueList ? 'form_data_source_column' : 'form_field_api_columnId')}
                  </th>
                  {type === 'form-external-api' &&
                    ['bindingList', 'systemBindingList'].includes(bindingKey) && (
                      <th>{t('form_field_data_type')}</th>
                    )}
                  {type === 'form-external-api' && bindingKey === 'mediaBindingList' && (
                    <th>{t('form_field_allow_multiple')}</th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {properties[bindingKey].map((item, index) => (
                  <tr key={index}>
                    {bindingKey === 'systemBindingList' ? (
                      <td>{t(otherDetails.allFormElementsObj[item.fieldId].fieldName)}</td>
                    ) : (
                      <td>
                        {otherDetails.allFormElementsObj[item.fieldId]?.fieldName ||
                          item?.fieldId || t('form_untitled_field')}
                      </td>
                    )}

                    <td>{columnValueList ? columnValuesMap[item.columnId] : item.columnId}</td>
                    {type === 'form-external-api' &&
                      ['bindingList', 'systemBindingList', 'bindingCustomList'].includes(bindingKey) && (
                        <td>{t(dataTypeValuesMap[item.dataType])}</td>
                      )}

                    {type === 'form-external-api' && bindingKey === 'mediaBindingList' && (
                      <td>{t(item.allowMultiple ? 'text_yes' : 'text_no')}</td>
                    )}
                    <td className='text-center'>
                      <img
                        src={trashIcon}
                        className='cursor-pointer'
                        onClick={() => removeFromList(index)}
                      />
                    </td>
                  </tr>
                ))}
                {userElementsList.length > 0 && (
                  <tr className='mt-2'>
                    <td>
                      {bindingKey === 'bindingCustomList'  ? (
                        <TextField
                          name={'fieldId'}
                          value={values.fieldId}
                          displayName={''}
                          handleChange={(name, value) => handleChangeValues(name, value)}
                          classes='w-100'
                          shrink
                        />
                      ) : (
                        <SelectField
                          label={userElementsListMap[values.fieldId] ?? 'select_select'}
                          listValues={userElementsList}
                          icon={arrowDownIcon}
                          handleChange={handleChangeValues}
                          selectedValues={[]}
                          name={'fieldId'}
                          isAutoClose={true}
                          variant='white-1'
                          menuClasses='width-max-content'
                          shrink
                        />
                      )}
                    </td>
                    <td>
                      {columnValueList ? (
                        <SelectField
                          label={columnValuesMap[values.columnId] ?? 'select_select'}
                          listValues={columnValueList}
                          icon={arrowDownIcon}
                          handleChange={handleChangeValues}
                          selectedValues={[]}
                          isAutoClose={true}
                          name={'columnId'}
                          variant='white-1'
                          menuClasses='width-max-content'
                          shrink
                        />
                      ) : (
                        <TextField
                          name={'columnId'}
                          value={values.columnId}
                          displayName={''}
                          handleChange={(name, value) => handleChangeValues(name, value)}
                          classes='w-100'
                          shrink
                        />
                      )}
                    </td>
                    {type === 'form-external-api' &&
                      ['bindingList', 'systemBindingList', 'bindingCustomList'].includes(bindingKey) && (
                        <td>
                          <SelectField
                            label={dataTypeValuesMap[values.dataType] ?? 'select_select'}
                            listValues={DataTypeValues}
                            icon={arrowDownIcon}
                            handleChange={handleChangeValues}
                            selectedValues={[]}
                            isAutoClose={true}
                            name={'dataType'}
                            variant='white-1'
                            menuClasses={'w-100'}
                            shrink
                          />
                        </td>
                      )}
                    {type === 'form-external-api' && bindingKey === 'mediaBindingList' && (
                      <td>
                        <SingleCheckBoxField
                          name={'allowMultiple'}
                          value={values.allowMultiple}
                          handleChange={handleChangeValues}
                          text={<></>}
                        />
                      </td>
                    )}
                    <td>
                      <FormButton
                        text='button_add'
                        variant='green-1'
                        disabled={values.fieldId === '' || values.columnId === ''}
                        onClick={addToList}
                        minWidth
                        classes='px-2'
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div style={{ height: '3rem', visibility: 'hidden' }}></div>
          </div>
        </Fragment>
      </ToggleBoxBody>
    </ToggleBox>
  );
}
BindingComponent.propTypes = {
  title: PropTypes.string,
  properties: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  columnValueList: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  bindingKey: PropTypes.string,
  type: PropTypes.string,
};
export default BindingComponent;
