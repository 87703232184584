import React, { useState } from 'react';
import PropTypes from 'prop-types';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import BindingComponent from '../BindingComponent';
import ExternalApiFields from './ExternalApiFields';
import SelectField from 'components/form-components/SelectField';
import TextField from 'components/form-components/TextField';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';

function ExternalApiBasicProperties({ properties, handleChange, otherDetails }) {
  const [toggler, setToggler] = useState(false);

  const triggerElementsList = Object.values(otherDetails.onChangeTriggerElementsObj)
    .map((singleFormElement) => ({
      value: singleFormElement.fieldId,
      displayValue: singleFormElement.fieldName,
    }))
    .filter((option) => option.displayValue !== properties.fieldTitle);

  return (
    <>
      {properties.triggerType === 'onchange_other' && (
        <SelectField
          placeholder='form_field_onchange_trigger_field'
          label={
            triggerElementsList.find((o) => o.value === properties['onChangeTriggerFieldId'])
              ?.displayValue ?? 'select_select'
          }
          listValues={triggerElementsList}
          icon={arrowDownIcon}
          handleChange={handleChange}
          selectedValues={[]}
          name={'onChangeTriggerFieldId'}
          isAutoClose={true}
          variant='white-1'
          menuClasses='width-max-content'
          classes='mb-2'
          shrink
        />
      )}
      {['onload', 'onchange_other'].includes(properties.triggerType) && (
        <TextField
          name={'selfFillColumnKey'}
          value={properties['selfFillColumnKey']}
          displayName={'form_field_self_fill_column_key'}
          handleChange={handleChange}
          error={''}
          touched={true}
          classes='mb-2'
          shrink
        />
      )}
      <ToggleBox classes='mt-3'>
        <ToggleBoxHeader
          show={toggler}
          toggleBoxOpen={() => setToggler(!toggler)}
          title='form_external_data_source_details'
        />
        <ToggleBoxBody show={toggler}>
          <ExternalApiFields
            properties={properties}
            handleChange={handleChange}
            errors={{}}
            touched={{}}
            type='field-external-api'
          />
        </ToggleBoxBody>
      </ToggleBox>
      <BindingComponent
        properties={properties}
        handleChange={handleChange}
        otherDetails={otherDetails}
      />
    </>
  );
}
ExternalApiBasicProperties.propTypes = {
  properties: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default ExternalApiBasicProperties;
