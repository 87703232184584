import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import PermissionsTable from './PermissionsTable';
import SelectField from 'components/form-components/SelectField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import UpdatePermissions from './UpdatePermissions';
import { getFormDataSourcesForPermission, getPermissionList } from './utils';
import { CallAPI } from 'actions/General';
import UpdateSectionPermissions from './UpdateSectionPermission';

const Permissions = ({ formDetails, getFormDetail, dataSourcesList }) => {
  const [permissionList, setPermissionList] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [formDataSources, setFormDataSources] = useState([]);
  const [dataSourceOptions, setDataSourceOptions] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [filterColumns, setFilterColumns] = useState([]);
  const [dataSourceBindingMap, setDataSourceBindingMap] = useState({});
  const [loader, setLoader] = useState(false);

  const handleFilterToggle = (_, value) => {
    if (!value) setSelectedDataSource(null);
    setEnableFilter(value);
  };

  const handleFilterCheckChange = (value, index) => {
    const tempFilterColumns = [...filterColumns];

    tempFilterColumns[index] = { ...tempFilterColumns[index], value };
    setFilterColumns(tempFilterColumns);
  };

  const saveDataSourceFilterPermission = async () => {
    const payload = {
      formId: formDetails._id,
      dataSourceFilterPermission: {
        isEnabled: enableFilter,
        dataSourceId: selectedDataSource,
        dataSourceColumns: !enableFilter
          ? []
          : filterColumns
              .filter((c) => c.value)
              .map((c) => ({ isKeyFilter: c.isKeyFilter, columnId: c.id })),
      },
    };

    const result = await CallAPI('SAVE_FORM', payload, null, setLoader, null, null);
    if (result.status) {
      getFormDetail(formDetails._id);
    }
  };

  useEffect(() => {
    setPermissionList(getPermissionList(formDetails));
    setEnableFilter(formDetails.dataSourceFilterPermission?.isEnabled ?? false);
    setSelectedDataSource(formDetails.dataSourceFilterPermission?.dataSourceId ?? null);
  }, [formDetails]);

  useEffect(() => {
    const { filteredDataSources, bindingMap } = getFormDataSourcesForPermission(
      formDetails.currentVersion.elements,
      dataSourcesList,
    );
    setDataSourceBindingMap(bindingMap);
    setFormDataSources(filteredDataSources);
    setDataSourceOptions(
      filteredDataSources.map((d) => ({ displayValue: d.schemaName, value: d._id })),
    );
  }, [dataSourcesList]);

  useEffect(() => {
    if (selectedDataSource) {
      const dSource = formDataSources.find((d) => d._id === selectedDataSource);

      if (dSource) {
        const keyFilter = [];
        const filterList = [];
        const existingSelectionIds = (
          formDetails.dataSourceFilterPermission?.dataSourceColumns ?? []
        ).map((c) => c.columnId);

        dSource.dataSourceSchema.forEach((column) => {
          if (dataSourceBindingMap[dSource._id].includes(column.columnId)) {
            if (column.isKeyFilter)
              keyFilter.push({
                id: column.columnId,
                displayText: column.columnName,
                value: existingSelectionIds.includes(column.columnId),
                isKeyFilter: true,
              });
            else if (column.isFilter)
              filterList.push({
                id: column.columnId,
                displayText: column.columnName,
                value: existingSelectionIds.includes(column.columnId),
                isKeyFilter: false,
              });
          }
        });
        setFilterColumns([...keyFilter, ...filterList]);
      }
    } else setFilterColumns([]);
  }, [selectedDataSource]);

  return (
    <div className='supervisor-settings pb-5 px-5'>
      <div className='d-flex justify-content-between theme-font-inter-medium theme-size-1_1 pt-4'>
        <label>{formDetails.title ?? ''}</label>
        <label>Manage your form permissions</label>
      </div>
      {formDataSources.length > 0 && (
        <div className='py-4'>
          <div className='d-flex gap-3 align-items-center'>
            <ToggleSwitch
              name='enableFilter'
              value={enableFilter}
              handleChange={handleFilterToggle}
            />
            <label>Enable Data Filter</label>
          </div>
          {enableFilter && (
            <div className='d-flex gap-3 align-items-center mt-3'>
              <SelectField
                label={
                  dataSourceOptions.find((d) => d.value === selectedDataSource)?.displayValue ??
                  'select_data_source'
                }
                listValues={dataSourceOptions}
                icon={arrowDownIcon}
                handleChange={(_, value) => setSelectedDataSource(value)}
                selectedValues={[]}
                name='selectedDataSource'
                isAutoClose={true}
                variant='white-1'
                classes='width-max-content'
                menuClasses={'w-100'}
              />
              {filterColumns.map((column, index) => (
                <SingleCheckBoxField
                  key={index}
                  name={column.id}
                  value={column.value}
                  handleChange={(_, value) => handleFilterCheckChange(value, index)}
                  text={<span>{column.displayText}</span>}
                  classes='w-auto'
                  shrink
                />
              ))}
            </div>
          )}
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            onClick={saveDataSourceFilterPermission}
            loading={loader}
            disabled={enableFilter && (!selectedDataSource || filterColumns.every((c) => !c.value))}
            classes='mt-3'
          />
        </div>
      )}
      {!selectedPermission ? (
        <PermissionsTable
          permissionList={permissionList}
          permissionRowClick={(row) => {
            setSelectedPermission(row);
          }}
        />
      ) : selectedPermission && selectedPermission.key !== 'managerSections' ? (
        <UpdatePermissions
          formId={formDetails._id}
          selectedPermission={selectedPermission}
          getFormDetail={getFormDetail}
          goBack={() => {
            setSelectedPermission(null);
          }}
        />
      ) : (
        <UpdateSectionPermissions
          selectedPermission={selectedPermission}
          getFormDetail={getFormDetail}
          goBack={() => {
            setSelectedPermission(null);
          }}
          formDetails={formDetails}
        />
      )}
    </div>
  );
};

Permissions.propTypes = {
  formDetails: PropTypes.object.isRequired,
  getFormDetail: PropTypes.func.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
};

export default Permissions;
