import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormButton from 'components/form-components/FormButton';
import TextField from 'components/form-components/TextField';
import SelectField from 'components/form-components/SelectField';
import selectArrow from 'assets/images/select-arrow.png';
import DateField from 'components/form-components/DateField';
import { CallAPI } from 'actions/General';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { useLocation } from 'react-router-dom';
import NumberField from 'components/form-components/NumberField';
import ImageInput from 'components/form-components/ImageInput';
import { cloneDeep } from 'lodash';
import Error from 'components/Error';

const AddDataSourceEntry = ({ columnsList, closeRow, editedRowData }) => {
  const location = useLocation();
  const [newRecord, setNewRecord] = useState(null);
  const [loader, setLoader] = useState(false);
  const [ApiError, setApiError] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const setNewObject = () => {
    let record = columnsList.reduce((acc, item) => {
      acc[item.columnId] = '';
      return acc;
    }, {});

    setNewRecord(record);
  };

  useEffect(() => {
    if (Object.keys(editedRowData).length === 0) setNewObject();
  }, [columnsList]);
  useEffect(() => {
    if (Object.keys(editedRowData).length > 0) {
      let rowData = cloneDeep(editedRowData);
      delete rowData.row_datasource_entry_id;
      setNewRecord(rowData);
    } else setNewObject();
  }, [editedRowData]);

  const submit = async () => {
    setTouched({});
    const locationColumns = columnsList.filter((column) => column.type === 'geoCoordinates');
    const locationErrors = {};
    if (locationColumns.length) {
      const regex = /^\d+(\.\d+)?,\d+(\.\d+)?$/;
      locationColumns.forEach((column) => {
        const validationPassed = Object.entries(newRecord).find(
          (record) => record[0] === column.columnId && (!record[1] || regex.test(record[1])),
        );
        if (!validationPassed) locationErrors[column.columnId] = 'geo-coordinates format';
      });
    }
    if (Object.keys(locationErrors).length) {
      setErrors({ ...errors, ...locationErrors });
      return;
    }
    let apiName = 'ADD_ROW_IN_DATASOURCE';
    let rowId = null;
    if (editedRowData.row_datasource_entry_id) {
      apiName = 'Edit_ROW_IN_DATASOURCE';
      rowId = editedRowData.row_datasource_entry_id;
    }

    let result = await CallAPI(
      apiName,
      {
        dataSourceId: location.state.dataSource._id,
        entryValues: Object.entries(newRecord).map(([key, value]) => {
          return {
            columnId: key,
            keyValue: value,
          };
        }),
      },
      rowId,
      setLoader,
      setApiError,
      null,
    );
    if (result.status) {
      closeRow('add');
    }
  };

  const handleChange = (field, value) => {
    const isLocationColumn = columnsList.find(
      (c) => c.columnId === field && c.type === 'geoCoordinates',
    );
    if (isLocationColumn) {
      setTouched({ ...touched, [field]: true });
      setErrors({ ...errors, [field]: undefined });
    }
    setNewRecord({ ...newRecord, [field]: value });
  };

  return (
    <>
      {columnsList && newRecord && (
        <>
          {ApiError ? (
            <tr>
              <th></th>
              <th>{<Error msg={ApiError} classList={'m-0 w-auto'} />}</th>
              <th colSpan={100}></th>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <th></th>
            {columnsList.map((singleColumn, index) => {
              const type = singleColumn.type;
              const name = singleColumn.columnId;
              return (
                <th
                  key={index}
                  className={`min-width-160 ${Object.keys(errors).length ? 'align-baseline' : ''}`}
                >
                  {type === 'text' ? (
                    <TextField
                      name={name}
                      value={newRecord[name]}
                      handleChange={handleChange}
                      shrinkFieldOnly
                      classes='min-width-160'
                    />
                  ) : type === 'number' ? (
                    <NumberField
                      name={name}
                      value={parseInt(newRecord[name]) || 0}
                      handleChange={handleChange}
                      shrinkFieldOnly
                      classes='min-width-160'
                    />
                  ) : type === 'dateTime' ? (
                    <DateField
                      placeholder=''
                      name={name}
                      value={newRecord[name]}
                      handleChange={handleChange}
                      shrink
                      classes='min-width-160'
                    />
                  ) : type === 'geoCoordinates' ? (
                    <TextField
                      name={name}
                      value={newRecord[name] || ''}
                      handleChange={handleChange}
                      shrinkFieldOnly
                      classes='min-width-160'
                      error={errors[name]}
                      touched={touched[name]}
                    />
                  ) : type === 'boolean' ? (
                    <SelectField
                      name={name}
                      label={
                        newRecord[name] !== '' ? newRecord[name]?.toString() : `Select ${name}`
                      }
                      listValues={[
                        { value: 'false', displayValue: 'False' },
                        { value: 'true', displayValue: 'True' },
                      ]}
                      handleChange={handleChange}
                      variant='white-1'
                      icon={selectArrow}
                      isAutoClose={true}
                      menuClasses='w-100'
                    />
                  ) : type === 'image' ? (
                    <>
                      <ImageInput
                        isHorizontal
                        name={name}
                        value={newRecord[name]}
                        handleChange={handleChange}
                        shrinkFieldOnly
                        classes='mt-auto'
                      />
                    </>
                  ) : (
                    ''
                  )}
                </th>
              );
            })}
            <th></th>
          </tr>
          <tr>
            <th></th>
            <th colSpan={100}>
              <div className='d-flex gap-2 pb-2'>
                <FormButton
                  text='button_cancel'
                  variant='white-1'
                  onClick={() => closeRow('cancel')}
                />
                <FormLoadingButton
                  loading={loader}
                  text={editedRowData.row_datasource_entry_id ? 'button_update' : 'button_save'}
                  variant='green-1'
                  onClick={submit}
                />
              </div>
            </th>
          </tr>
        </>
      )}
    </>
  );
};

AddDataSourceEntry.propTypes = {
  columnsList: PropTypes.array.isRequired,
  closeRow: PropTypes.func.isRequired,
  editedRowData: PropTypes.object.isRequired,
};

export default AddDataSourceEntry;
