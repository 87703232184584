/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import './CustomMentionInput.css';

const CustomMentionInput = ({
  name,
  placeholder,
  icon,
  value,
  error,
  touched,
  handleChange,
  classes,
  isHorizontal,
  disabled,
  isDisplayAbleOnly,
  rows,
  mentionsOptions,
  UsedFieldsMap
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState(mentionsOptions);
  const [focusIndex, setFocusIndex] = useState(0);
  const [cursorCurrentPosition, setCursorCurrentPosition] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);

  useEffect(()=>{
    handleChange(name, inputValue);

    // // update the mapping object below

    // //extract the fieldValues in editableDiv
    // // Regular expression to match anything between #[ and ]#
    // const regex = /#\[(.*?)\]#/g;
    // // Use match() to find all matches and map the results to remove extra characters
    // const matches = [...inputValue.matchAll(regex)].map(match => match[1]);
    // const mentionsOptionsValueArray = mentionsOptions.map((item, i) => {
    //   return {
    //     value: item.value,
    //     index: i
    //   }
    // });
    // const tempObj = {}
    // matches.forEach((item)=>{
    //   if(mentionsOptionsValueArray.includes(item)){
    //     // tempObj[item] = 
    //   }
    // });

    // // UsedFieldsMap[suggestion.display] = suggestion.value;

    // const fieldValuesArray = 
    // console.log("pkol: 1", 
    //   inputRef.current, "\n",
    //   inputValue, "\n",
    //   value, "\n",
    //   matches
    // );
  }, [inputValue]);

  useEffect(()=>{
    inputRef.current.innerHTML = value;
  }, []);

  const usedMentions = {};

  const handleInputChange = (e) => {
    console.log("pokkoji: handleInputChange", e);
    // const content = e.target.innerHTML;
    const content = e.target.innerText;
    console.log("sdvvd: ", content, cursorCurrentPosition, getCaretPosition())
    setInputValue(content);

    // Check if the latest character entered is '#'
    if (e.nativeEvent.data === '#') {
      setShowSuggestions(true); // Show suggestions when trigger character is typed
      setFocusIndex(0); // set focus to first element in suggestion list
    } else {
      // setSuggestions([]);
      setShowSuggestions(false); // Hide suggestions if the latest character is not '#'
    }
  };

  const wrapSelectedMentionWithCustomCode = (suggestion, caretPosition, fieldText)=>{
    const modifiedContent = fieldText.slice(0, caretPosition-1) + `#[${suggestion.display}]#` + fieldText.slice(caretPosition);
    return modifiedContent;
  }

  const handleSelect = (suggestion) => {
    const contentEditableElement = inputRef.current;
    // Focus back on contentEditable div
    // contentEditableElement.focus();
    // const content = contentEditableElement.innerHTML;
    const content = inputValue;
    // const caretPosition = getCaretPosition();
    const caretPosition = cursorCurrentPosition;
    console.log("efrdg handleSelect: ", caretPosition, inputValue);

    // //saving cursor position
    // const contentEditableElement = inputRef.current;
    // const content = contentEditableElement.innerHTML;
    // // const caretPosition = getCaretPosition();
    // console.log("dsivrvc: ", getCaretPosition(), content);

    // Insert mention at the caret position
    const newContent = wrapSelectedMentionWithCustomCode(suggestion, caretPosition, content);
    // Update content
    contentEditableElement.innerHTML = newContent;
    
    setInputValue(newContent);
    // setSuggestions([]);
    // Set the caret position after the inserted mention
    setCaretPosition(caretPosition + (`#[${suggestion.display}]#`.length - 1));
    setShowSuggestions(false);

    usedMentions[suggestion.display] = suggestion.value;
    UsedFieldsMap[suggestion.display] = suggestion.value;
    console.log("vycf: ", suggestion, UsedFieldsMap);
  };

  const getCaretPosition = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const caretPosition = range.startOffset;
    console.log("unvf: ", selection, range, caretPosition);
    return caretPosition;
  };

  const handleKeyDown = (e) => {
    console.log("pokkoji: handleKeyDown", e);
    // Prevent cursor to change position when below keys are pressed and suggestions are opened
    if (showSuggestions &&
      (
        e.key === 'ArrowDown' ||
        e.key === 'ArrowUp' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        e.key === 'Enter'
      )) {
      e.preventDefault();
    }
    // force div input to single line
    if(e.key === 'Enter'){
      e.preventDefault();
    }

    // if(suggestion e.key === 'Enter'){
    //   e.preventDefault();
    // }


    // else{
    //   //saving cursor position
    //   const contentEditableElement = inputRef.current;
    //   const content = contentEditableElement.innerHTML;
    //   const caretPosition = getCaretPosition();
    // }


    // Traverse current focus in mentions dropdown list
    if (e.key === 'ArrowDown') {
      setFocusIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    }
    if (e.key === 'ArrowUp') {
      setFocusIndex((prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length);
    }
    if (e.key === 'Enter' && suggestions[focusIndex]) {
      const caretPosition = getCaretPosition();
      console.log("jikj: handleSelect", suggestions[focusIndex]);
      // prevent enter on disabled or heading item in  mentionsOptions
      if(suggestions[focusIndex].type==='option'){
        handleSelect(suggestions[focusIndex]);
      }
    }


  };

  const handleSuggestionClick = (suggestion) => {
    console.log("pokkoji: handleSuggestionClick", suggestion);
    handleSelect(suggestion);
  };

  const setCaretPosition = (position) => {
    const range = document.createRange();
    const selection = window.getSelection();

    range.setStart(inputRef.current.firstChild, position);
    range.setEnd(inputRef.current.firstChild, position);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleKeyUp = (e) => {
    // save cursor latest position when user relase key
    // if(!showSuggestions){
      const latestCaretPosition = getCaretPosition();
      setCursorCurrentPosition(latestCaretPosition);
      console.log("Caret Position After:", getCaretPosition());
      console.log("efrdg handleKeyUp: ", cursorCurrentPosition, inputValue);
    // }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        contentEditable
        ref={inputRef}
        onKeyUp={handleKeyUp}
        onInput={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type # to mention someone"
        className='custom-react-mention-style'
        style={{
          minHeight: '100px',
          border: '1px solid #ccc',
          padding: '8px',
          overflowY: 'auto',
          position: 'relative',
        }}
      ></div>

      {/* Custom Suggestion Container */}
      {showSuggestions && suggestions.length > 0 && (
        <div
          ref={suggestionsRef}
          style={{
            position: 'absolute',
            top: '110%',
            left: 0,
            right: 0,
            border: '1px solid #ccc',
            backgroundColor: 'white',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            maxHeight: '150px',
            overflowY: 'auto',
            zIndex: 1,
          }}
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: '8px',
                cursor: 'pointer',
                backgroundColor: focusIndex === index ? '#f0f0f0' : 'transparent',
                ...(suggestion.type==='heading' ? {pointerEvents: 'none', opacity: '0.5'} : {})
              }}
              // style={{pointerEvents: 'none', opacity: '0.5'}}
              onMouseEnter={() => setFocusIndex(index)}
            >
              {suggestion.display}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomMentionInput;
