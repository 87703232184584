import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FormFields from './FormFields';
import FormPreview from './FormPreview';
import FormProperties from './FormProperties';
import { useTranslation } from 'react-i18next';
import dragDropIcon from 'assets/images/icons/drag-drop.svg';
import { defaultMediaProperties, formElementsDefaultProperties, rightToggleList } from './utils';
import { cloneDeep, merge } from 'lodash';
import Error from 'components/Error';
import FormDesignHeader from './FormDesignHeader';
import { createUngroupedTriggersArray } from './actions';

function DesignForm({ formDetails, getFormDetail, dataSourcesList }) {
  const { t } = useTranslation();
  const propertiesSectionRef = useRef();
  const [selectedFieldCategory, setSelectedFieldCategory] = useState('user-field');
  const [selectedField, setSelectedField] = useState(null);
  const [formProperties, setFormProperties] = useState(null);
  const [draggedElement, setDraggedElement] = useState(null);
  const [APIError, setAPIError] = useState('');

  useEffect(async () => {
    let bindingElementsObj = {};
    let allElementsObj = {};
    let allTriggerElementsObj = {};
    const pastTimeStamp = Math.ceil(Date.now() / 1000) - 30 * 24 * 60 * 60; // one month earlier
    let formProps = {
      supervisorFields: { elements: [] },
      otherDetails: {
        bindingElementsObj: {},
        allFormElementsObj: {},
        onChangeTriggerElementsObj: {},
        hasStatusFieldInForm: false,
      },
    };
    let pageNumber = 0;
    let childElementIds = [];
    if (formDetails?.currentVersion?.elements) {
      formDetails.currentVersion.elements.map((singleElement, index) => {
        if (singleElement.elementType === 'page') pageNumber++;
        let properties = JSON.parse(singleElement.properties);
        // add all missing properties of a single processed element
        properties = merge(
          cloneDeep(formElementsDefaultProperties[singleElement.elementType]),
          properties,
        );
        // make all elements obj to bind with other elements
        if (
          !['page', 'section', 'image', 'dataSource', 'externalApi'].includes(
            singleElement.elementType,
          )
        ) {
          allElementsObj = {
            ...allElementsObj,
            [singleElement.elementGlobalId]: {
              fieldName: properties.basicProperties.fieldTitle,
              fieldId: singleElement.elementGlobalId,
            },
          };
        }
        // make elements object for on change triggers elements selection
        if (!['page', 'section', 'image'].includes(singleElement.elementType)) {
          allTriggerElementsObj = {
            ...allTriggerElementsObj,
            [singleElement.elementGlobalId]: {
              fieldName: properties.basicProperties.fieldTitle,
              fieldId: singleElement.elementGlobalId,
            },
          };
        }
        // check already created elements those doesn't contain fieldUniqueId key, populate fieldUniqueId Key
        if (!['page', 'section', 'image'].includes(singleElement.elementType)) {
          if (
            !Object.keys(properties.otherProperties).includes('fieldUniqueId') ||
            properties.otherProperties.fieldUniqueId === ''
          ) {
            properties.otherProperties.fieldUniqueId = `field_${pastTimeStamp + index * 5}`;
          }
        }

        // if processed element is data source element then update the binding elements list
        if (
          singleElement.elementType === 'dataSource' ||
          singleElement.elementType === 'externalApi'
        ) {
          const currentElementBinding = properties.basicProperties.bindingList;
          if (currentElementBinding.length) {
            currentElementBinding.map((binding) => {
              if (binding && binding.fieldId)
                bindingElementsObj = {
                  ...bindingElementsObj,
                  [binding.fieldId]: {
                    fieldName: properties.basicProperties.fieldTitle,
                    fieldId: singleElement.elementGlobalId,
                  },
                };
            });
          }
        }
        let triggers = [];
        if (singleElement.triggers) {
          const { triggerArr, childIds } = createUngroupedTriggersArray(singleElement.triggers);
          triggers = triggerArr;
          childElementIds.push(...childIds);
        }

        // update basic properties for older forms
        updateBasicPropertiesForNewChanges(singleElement.elementType, properties);

        let addedElement = {
          company: singleElement.company,
          elementGlobalId: singleElement.elementGlobalId,
          elementType: singleElement.elementType,
          _id: singleElement._id,
          isOpen: false,
          properties,
          isInSection: singleElement.sectionNumber > 0 && singleElement.elementType !== 'section',
          ...(triggers.length > 0 && { triggers }),
        };

        if (childElementIds.includes(addedElement.elementGlobalId)) {
          addedElement.triggerFlowReferenceId = null;
          addedElement.childReferenceId = addedElement.elementGlobalId;
        }

        if (triggers.length) {
          console.log(triggers);
          console.log(addedElement);
        }
        if (formProps['page' + pageNumber]) {
          formProps['page' + pageNumber].elements.push(addedElement);
        } else {
          formProps['page' + pageNumber] = {
            elements: [addedElement],
          };
        }
      });
      let hasStatusFieldInForm = false;
      formDetails.currentVersion.supervisorElements.map((singleElement) => {
        const properties = JSON.parse(singleElement.properties);

        // For adding default media properties in old supervisor fields
        if (singleElement.elementType !== 'section') {
          if (!properties.mediaProperties || Object.keys(properties.mediaProperties).length === 0)
            properties.mediaProperties = { ...defaultMediaProperties };
        }

        let sectionWorkFlows = null;

        if (singleElement.elementType === 'section') {
          sectionWorkFlows = (singleElement.sectionWorkFlows ?? []).map((workflow) => ({
            ...workflow,
            workFlowSourceElementReferenceId: null,
            sourceElementReferenceId: workflow.sourceElementGlobalId ?? null, // Only for frontend mapping
          }));
        }
        let triggers = [];
        if (singleElement.triggers) {
          const { triggerArr, childIds } = createUngroupedTriggersArray(singleElement.triggers);
          triggers = triggerArr;
          childElementIds.push(...childIds);
        }
        let addedElement = {
          company: singleElement.company,
          elementGlobalId: singleElement.elementGlobalId,
          elementType: 'supervisor_' + singleElement.elementType,
          _id: singleElement._id,
          properties,
          isOpen: false,
          isInSection: singleElement.sectionNumber > 0 && singleElement.elementType !== 'section',
          ...(sectionWorkFlows && { sectionWorkFlows }),
          ...(triggers.length > 0 && { triggers }),
        };

        if (childElementIds.includes(addedElement.elementGlobalId)) {
          addedElement.triggerFlowReferenceId = null;
          addedElement.childReferenceId = addedElement.elementGlobalId;
        }

        formProps.supervisorFields.elements.push(addedElement);
        if (addedElement.elementType === 'supervisor_status') {
          hasStatusFieldInForm = true;
        }
      });
      if (formDetails.currentVersion.statusElement) {
        let statusElement = formDetails.currentVersion.statusElement;
        const properties = JSON.parse(statusElement.properties);

        // For adding default media properties in old supervisor fields
        if (!properties.mediaProperties || Object.keys(properties.mediaProperties).length === 0)
          properties.mediaProperties = { ...defaultMediaProperties };

        let addedElement = {
          company: statusElement.company,
          elementGlobalId: statusElement.elementGlobalId,
          elementType: 'supervisor_' + statusElement.elementType,
          _id: statusElement._id,
          properties,
          isOpen: false,
          isInSection: statusElement.sectionNumber > 0,
        };
        // formProps.supervisorFields.elements.push(addedElement);
        let supervisorElementsList = formProps.supervisorFields.elements;

        if (supervisorElementsList.length !== 0) {
          let userElementsCount = formDetails?.currentVersion?.elements
            ? formDetails.currentVersion.elements.length
            : 0;
          let statusFieldIndex = statusElement.position - userElementsCount - 1;
          console.log(statusElement.position, userElementsCount, statusFieldIndex);
          supervisorElementsList = [
            ...supervisorElementsList.slice(0, statusFieldIndex),
            addedElement,
            ...supervisorElementsList.slice(statusFieldIndex),
          ];
        } else supervisorElementsList = [addedElement];
        formProps.supervisorFields.elements = supervisorElementsList;
        hasStatusFieldInForm = true;
      }
      if (Object.keys(formProps).length < 3) {
        formProps = {
          ...formProps,
          page1: {
            elements: [
              { elementType: 'page', properties: cloneDeep(formElementsDefaultProperties.page) },
            ],
          },
        };
      }
      formProps.otherDetails.bindingElementsObj = bindingElementsObj;
      formProps.otherDetails.allFormElementsObj = allElementsObj;
      formProps.otherDetails.onChangeTriggerElementsObj = allTriggerElementsObj;
      formProps.otherDetails.hasStatusFieldInForm = hasStatusFieldInForm;
    } else {
      formProps = {
        ...formProps,
        page1: {
          elements: [
            { elementType: 'page', properties: cloneDeep(formElementsDefaultProperties.page) },
          ],
        },
      };
    }
    const orderedFormProps = Object.keys(formProps)
      .sort()
      .reduce((obj, key) => {
        obj[key] = formProps[key];
        return obj;
      }, {});
    setFormProperties(orderedFormProps);
  }, [formDetails]);

  const updateBasicPropertiesForNewChanges = (elementType, properties) => {
    // ChoiceList option is removed from data source type. So, replacing existing forms subtype
    if (elementType === 'dataSource') {
      if (properties.basicProperties.subType === 'choiceList')
        properties.basicProperties.subType = 'dropdown';
    }
  };

  return (
    <>
      {formProperties && (
        <div className='form-design'>
          <FormDesignHeader
            setAPIError={setAPIError}
            formId={formDetails._id}
            getFormDetail={getFormDetail}
            formDetails={formDetails}
            formProperties={cloneDeep(formProperties)}
          />
          {APIError && <Error msg={APIError} />}
          {formProperties && (
            <div className='section-layout'>
              <section>
                <div className='section-header-area'>
                  <div className='d-flex gap-2 align-items-center'>
                    <img src={dragDropIcon} />
                    <p className='theme-text-black-1 theme-size-1_2 mb-0'>
                      {t('form_template_fields')}
                    </p>
                  </div>
                  <p className='text-center mb-0 theme-size-0_9'>
                    {t('form_drag_elements_onto_your_template')}
                  </p>
                </div>
                <div className='theme-background-white-2 px-3 py-2 custom-shadow mb-1'>
                  {selectedFieldCategory === 'user-field' ? 'User Fields' : 'Manager Fields'}
                </div>
                <FormFields
                  selectedFieldCategory={selectedFieldCategory}
                  setDraggedElement={setDraggedElement}
                />
              </section>
              <section>
                {/* <div className='section-header-area'>
                  <div className='w-100 d-flex gap-2 justify-content-between align-items-center'> */}
                {/* <FormButton text='Tablet editor' variant='red-1' /> */}
                {/* <p className='mb-0 w-50 theme-text-black-1 theme-size-0_9'>
                      {t('form_rearrange_elements')}
                    </p>
                  </div>
                </div> */}
                <FormPreview
                  selectedField={selectedField}
                  selectedFieldCategory={selectedFieldCategory}
                  setSelectedFieldCategory={setSelectedFieldCategory}
                  setSelectedField={setSelectedField}
                  formProperties={formProperties}
                  draggedElement={draggedElement}
                  setDraggedElement={setDraggedElement}
                  setFormProperties={setFormProperties}
                  direction={formDetails?.textDirection === 'right_to_left' ? 'rtl' : 'ltr'}
                  dataSourcesList={dataSourcesList ? dataSourcesList : []}
                  propertiesSectionRef={propertiesSectionRef}
                />
              </section>

              <section ref={propertiesSectionRef} id='properties-section'>
                <FormProperties
                  selectedField={selectedField}
                  setSelectedField={setSelectedField}
                  formProperties={formProperties}
                  setFormProperties={setFormProperties}
                  toggleList={rightToggleList}
                  isNestedOption={false}
                  dataSourcesList={dataSourcesList ? dataSourcesList : []}
                />
              </section>
            </div>
          )}
        </div>
      )}
    </>
  );
}
DesignForm.propTypes = {
  formDetails: PropTypes.object.isRequired,
  getFormDetail: PropTypes.func.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
};
export default DesignForm;
